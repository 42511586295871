(function () {

    var LessonController = function ($scope, $stateParams, $location, $timeout, slidesFactory, autoScrollFactory, $state, $sce, filterTextFactory, dataTransferFactory, userFactory, userInit, factoryInit, colorInit) {
        $scope.user = userInit;
        $scope.lesson = factoryInit.fields;
        $scope.colors = colorInit;
        dataTransferFactory.set('completedMessage', $scope.lesson.completedMessage);

        if($state.current.name == 'guidedLessonWidget') {
            $scope.widget = true;
        }

        // Log this page view for analytics.
        analytics.page({
            title: 'LessonController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.previousAnswers = $scope.lesson.previousAnswers;
        $scope.saved = $scope.lesson.isSaved;

        $scope.selectedAnswers = [];

        $scope.primary = false;
        $scope.secondary = false;
        $scope.tertiary = false;

        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        function getHex(value) {
            if(value.length > 6) {
                value = value.replace('rgba', '');
                value = value.replace('rgb', '');
                value = value.replace('(', '');
                value = value.replace(')', '');
                value = value.split(',');

                return componentToHex(parseInt(value[0])) + componentToHex(parseInt(value[1])) + componentToHex(parseInt(value[2]));
            }

            return value;
        }

        if($scope.colors) {
            if($scope.colors.primary) {
                $scope.primary = getHex($scope.colors.primary.replace('#', ''));
            }
            if($scope.colors.secondary) {
                $scope.secondary = getHex($scope.colors.secondary.replace('#', ''));
            }
            if($scope.colors.tertiary) {
                $scope.tertiary = getHex($scope.colors.tertiary.replace('#', ''));
            }
        }

        if (!$stateParams.id || !$scope.lesson || !$scope.user) {
            $location.path($state.get('page404').url);
            return;
        }

        /*************************************************
         * filterTextFactory methods for use in view
         */

        $scope.filterText = function(text) {
            if(text) {
                return filterTextFactory.filterText(text);
            }
            return null;
        };

        $scope.flowplayerFilter = function(embedCode) {
            return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
        };

        $scope.updateProtocols = function(original) {
            return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
        };

        /**
         * End filterTextFactory methods
         *************************************************/

        $scope.currIdx = false;
        $scope.currStep = [];
        $scope.openDictionary = false;
        $scope.openIngredients = false;
        $scope.keyboardPressed = false;

        /************************************************
         * sliderFactory methods - Used to connect back-end functionality with the view
         */

        if($scope.lesson.termSet) {
            // Loop through the terms and create "entry" and "value" fields
            var terms = [];
            for(var x = 0; x < $scope.lesson.termSet.fields.terms.terms.length; x++) {
                terms[x] = {
                    'entry': $scope.lesson.termSet.fields.terms.terms[x].term,
                    'value': encodeURIComponent($scope.lesson.termSet.fields.terms.terms[x].term),
                    'definition': $scope.lesson.termSet.fields.terms.terms[x].definition,
                    'term': $scope.lesson.termSet.fields.terms.terms[x].term
                };
            }
            $scope.dictionary = slidesFactory.setTerms(terms);
        }
        $scope.steps = slidesFactory.setSteps($scope.lesson.pages);

        $scope.selectStep = function(index) {
            var shouldAnimate = Math.abs($scope.currIdx - index);
            if(shouldAnimate > 1) {
                // Stop slide animation before switching pages
                $('.slider-container .slider-content').css('transition', 'none');
                $timeout(function() {
                    // Re-enable slide animation after switching pages
                    $('.slider-container .slider-content').css('transition', 'all 400ms ease');
                }, 400);
            }

            // Update slides
            $scope.currStep = slidesFactory.selectStep(index);
            $scope.currIdx = slidesFactory.getCurrentIndex();
            // Update url page position
            $location.search('page',$scope.currIdx);
            // Custom fields
            $scope.isFinalStep = $scope.currStep[2] == undefined;
            $scope.isNote = ($scope.currStep[1].sys.contentType.sys.id.toLowerCase() !== 'imagepage' && $scope.currStep[1].sys.contentType.sys.id.toLowerCase() !== 'videopage');

            // update answers if quiz
            $timeout(function() {
                $scope.deflipQuiz();
                // Check if it's a quiz
                if($scope.currStep[1].sys.contentType.sys.id.toLowerCase() == 'questionpage') {
                    // update answers (if they have been stored in previousAnswers array)
                    for(var i = 0; i < $scope.previousAnswers.length; i++) {
                        if($scope.previousAnswers[i].questionId == $scope.currStep[1].fields.questionId) {
                            // Set answers
                            $scope.selectAnswer($scope.previousAnswers[i].answer, { 'currentTarget': $('.answer-' + $scope.previousAnswers[i].answer) });
                            if($scope.previousAnswers[i].answer > $scope.currStep[1].fields.answers.length) {
                                if ($scope.currStep[1].fields.answers && $scope.selectedAnswers[$scope.currIdx] > $scope.currStep[1].fields.answers.length) {
                                    $('.open-ended-answer-' + $scope.currStep[1].fields.questionId).val($scope.previousAnswers[i].answerText);
                                } else {
                                    if ($scope.currStep[1].fields.includeOther) {
                                        $('.open-ended-answer-' + $scope.currStep[1].fields.questionId).val($scope.previousAnswers[i].answerText);
                                    }
                                }
                            }
                        }
                    }
                    updateQuizHeights();
                }
            }, 200);

            // API call to tell Monj the user has advanced
            userFactory.advanceLesson($scope.user.userMonj.userId, $stateParams.id, $scope.currIdx);

            updateColors();
        };

        $scope.openTerm = function (elemOrig, hrefID) {
            if($scope.lesson.termSet) {
                $scope.termIdx = slidesFactory.openTerm(elemOrig, hrefID);
                $scope.openDictionary = true;
                $scope.toggleSliderMenu();
                autoScrollFactory.scrollTo('term-' + $scope.termIdx, 'dictionary');
            }
        };

        /**
         * End sliderFactory methods
         ************************************************/

        /**************************************************
         * Touch Navigation Directive Tools
         */
        $(document).unbind('selectNextStep').bind('selectNextStep', function () {
            $scope.nextStep();
            $scope.$apply();
        });
        $(document).unbind('selectPreviousStep').bind('selectPreviousStep', function () {
            $scope.prevStep();
            $scope.$apply();
        });
        /**
         * End Touch Navigation Directive Tools
         ************************************************/

        function updateQuizHeights() {
            // Check which side of quiz face has the tallest height, and set both heights to that setting.
            var quiz = $('.quiz-flipper');
            // Reset min heights
            quiz.find('.front .note').css('height', '100%');
            quiz.find('.back .note').css('height', '100%');

            var frontHeight = quiz.find('.front').innerHeight();
            var backHeight = quiz.find('.back').innerHeight();
            var tallestHeight = Math.max(frontHeight, backHeight);

            if(backHeight > frontHeight) {
                quiz.find('.front .note').css('height', tallestHeight);
            } else {
                quiz.find('.back .note').css('height', tallestHeight);
            }
        }

        $scope.convertToLetter = function(index) {
            return String.fromCharCode(65 + index);
        };

        $scope.nextStep = function() {
            $scope.selectStep(slidesFactory.getCurrentIndex() + 1);
        };
        $scope.prevStep = function() {
            $scope.selectStep(slidesFactory.getCurrentIndex() - 1);
        };

        $scope.selectAnswer = function(index, event) {
            $(event.currentTarget).siblings().removeClass('active');
            $(event.currentTarget).addClass('active');
            $scope.selectedAnswers[$scope.currIdx] = index;
            updateColors();
        };

        $scope.submitQuizAnswer = function(id) {
            var answer = $scope.selectedAnswers[$scope.currIdx];
            var openEndedAnswer = $('.open-ended-answer-' + id).val();
            var answerText = openEndedAnswer;
            var multipleChoice = $scope.currStep[1].fields.answers;
            $scope.questionNotAnswered = false;

            var isOther = false;
            
            // If there are multiple choice answers available...
            if(multipleChoice) {
                // Save the answerText from the selected answer
                answerText = multipleChoice[answer];
                // If the user submitted an open ended answer instead of one of the choices...
                if(answer >= multipleChoice.length) {
                    answerText = openEndedAnswer;
                    isOther = true;
                }
            }

            if(answer === undefined) {
                answer = null;
            }
            if(answerText === undefined) {
                answerText = null;
            }

            // If this is NOT the final page...
            if(!$scope.isFinalStep) {
                // If no answers were selected OR text submitted
                if( answer === null || answerText === null) {
                    // Move to the next slide and do not submit answer
                    $scope.selectStep(slidesFactory.getCurrentIndex() + 1);
                    return;
                }
            } else {
                // If no answers were selected AND no text submitted
                if( answer === null && answerText === null) {
                    $scope.questionNotAnswered = true;
                    // Flip the quiz and do not submit
                    $scope.flipQuiz(id);
                    return;
                }
            }

            if(multipleChoice) {
                // Loop through the answers, store selected answer data locally and append to previousAnswers monj object
                for (var i = 0; i < $scope.previousAnswers.length; i++) {
                    if($scope.previousAnswers[i].questionId) {
                        if (id == $scope.previousAnswers[i].questionId.replace(/[^\w\s]/gi, '')) {

                            // Only save if the answers have changed and exist
                            //if ($scope.previousAnswers[i].answer != answer || $scope.previousAnswers[i].answerText != answerText) {
                            sendAnswersAndAnimate(id, answer, answerText, isOther);
                            /*} else {

                                // Otherwise, just show the animation
                                $scope.flipQuiz(id);
                            }*/
                            $scope.previousAnswers[i] = {
                                "questionId": id,
                                "answer": answer,
                                "answerText": answerText
                            };
                            return;
                        }
                    } else {
                        console.log('One of the saved answers is missing its identifier and was skipped to avoid error.');
                    }
                }
            }

            // Store answer and submit
            $scope.previousAnswers.push({
                "questionId": id,
                "answer": answer,
                "answerText": answerText
            });
            $scope.currStep[1].submitted = true;
            sendAnswersAndAnimate(id, answer, answerText, isOther);
        };

        function sendAnswersAndAnimate(id, answer, answerText, isOther) {
            $scope.waiting = true;
            userFactory.answerLesson($scope.user.userMonj.userId, $stateParams.id, id, answer, answerText, isOther).then(function(result) {
                // Once submitted, flip the card and show the results.
                $scope.waiting = false;
                $scope.flipQuiz(id);
            });
        }

        $scope.flipQuiz = function(id) {
            $('.quiz-flipper.flip-container.flipper-' + id).addClass('flipped');
            autoScrollFactory.scrollTop();
            $scope.currStep[1].submitted = true;
        };

        $scope.deflipQuiz = function() {
            $('.quiz-flipper.flip-container').removeClass('flipped');
            autoScrollFactory.scrollTop();
            $scope.currStep[1].submitted = false;
        };

        $scope.openIEList = function () {
            $scope.openIngredients = true;

            $scope.toggleSliderMenu();
            autoScrollFactory.scrollTop('ingredients');
        };

        document.onkeydown = function(evt) {
            if(!$scope.keyboardPressed) {
                $scope.keyboardPressed = true;
                evt = evt || window.event;
                switch (evt.keyCode) {
                    case 37:
                        $scope.prevStep();
                        break;
                    case 39:
                        $scope.nextStep();
                        break;
                }
            }
            $timeout(function() {
                $scope.keyboardPressed = false;
            }, 200);
        };

        $scope.toggleSliderMenu = function(delay) {
            delay = delay ? delay : 0;
            $timeout(function() {
                if(!$scope.openMenu) {
                    $scope.openMenu = true;
                    clipPage();
                } else {
                    removePageClipping();
                    $scope.openMenu = false;
                    $scope.openDictionary = false;
                    $scope.openIngredients = false;
                }
            }, delay);
        };

        // Prevent view from scrolling beyond currently visible heights
        function clipPage() {
            $('#app-wrapper').css({'overflow':'hidden','height':'100vh'});
        }

        function removePageClipping() {
            $('#app-wrapper').css({'overflow':'initial','height':'100%'});
        }

        $scope.completeSinglePageLesson = function() {
            userFactory.completelesson($scope.user.userMonj.userId, $stateParams.id).then(function() {
                // Close awards
                $timeout(function() {
                    $('.completed-animation').removeClass('active');
                    //$location.path($state.get('authExperience').url);
                    $location.url($location.path());
                }, 5000);
            });
        };

        $scope.completeLesson = function() {
            $scope.waiting = true;
            userFactory.completelesson($scope.user.userMonj.userId, $stateParams.id).then(function() {
                $scope.waiting = false;
                if($scope.widget) {
                    $timeout(function () {
                        /*if (dataTransferFactory.get('applicationCloseFrame')) {
                            $timeout(function() {
                                $scope.goBack(true, true);
                            }, 400);
                        } else {*/
                            $scope.goBack(true, true);
                        //}
                    }, 3000);
                }
            });
            if(!$scope.widget) {
                return;
            }
            // Flip the card, timeout for 3 seconds, then return to homepage
            $('.slider-flipper.flip-container').addClass('flipped');
            dataTransferFactory.set('awardIssued', true);
            $scope.isFinalPage = true;
            $scope.isFinalStep = false;
        };

        $scope.goBack = function(resetPage, completeLesson) {
            if(resetPage) {
                dataTransferFactory.set('page', 'no-resume');
            } else {
                dataTransferFactory.set('page', $location.search().page);
            }
            if($scope.widget) {
                if($scope.closeFrame) {
                    if(!completeLesson) { completeLesson = false; }
					if($scope.stringify) {
						window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: completeLesson, stringy: true}), "*");
                        window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: completeLesson, stringy: true}), "*");
					}
					else {
						window.parent.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: completeLesson}, "*");
                        window.ReactNativeWebView.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: completeLesson}, "*");
					}
                    $('.completed-animation').removeClass('active');
                    $location.path($state.href('expandedLessonWidget', {id: $stateParams.id}).replace('#', ''));
                } else {
                    $location.path($state.href('expandedLessonWidget', {id: $stateParams.id}).replace('#', ''));
                }
            } else {
                $location.path($state.href('lesson', {id: $stateParams.id}).replace('#', ''));
            }
        };

        $(window).resize(function() {
            updateQuizHeights();
        });

        function updateColors() {
            if(dataTransferFactory.get('primaryColorChange')) {
                $scope.primary = dataTransferFactory.get('primaryColorChange');
            }
            if(dataTransferFactory.get('secondaryColorChange')) {
                $scope.secondary = dataTransferFactory.get('secondaryColorChange');
            }
            if(dataTransferFactory.get('tertiaryColorChange')) {
                $scope.tertiary = dataTransferFactory.get('tertiaryColorChange');
            }

            // Update colors if they have been included and is a widget
            $timeout(function() {
                if($scope.widget && $scope.primary) {
                    $('.slider-menu').css('background-color', '#' + $scope.primary);
                    $('.drilldown > header').css('background-color', '#' + $scope.primary);
                    $('.secondary-btn').css('background-color', '#' + $scope.primary);
                    $('term').css('color', '#' + $scope.primary);
                    $('.link').css('color', '#' + $scope.primary);
                    $('.slider-menu .parent-slider-menu .slider-menu-toggle-icon .steps-icon').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.videopage').css('background-image', 'url("./imgs/play-white-plain.svg")');
                    $('.note').css('background-color', '#' + $scope.primary);
                    if($scope.isNote) {
                        $('.arrows div').css('background-color', '#FFFFFF');
                    } else {
                        $('.arrows div').css('background-color', '#' + $scope.primary);
                    }
                    if($( document ).width() <= 768) {
                        $('.arrows div').css('background-color', '#FFFFFF');
                    }
                    //$('.quiz .question-number .number-border').css('background-color', '#' + $scope.primary);
                    $('.quiz .question-number').css('color', '#FFFFFF');
                    $('.quiz ul li.active .question-number').css('color', '#' + $scope.primary);
                    //$('.quiz ul li.active .question-number').css('background-color', '#FFFFFF');
                    $('.menu-container').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.questionpage').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.tippage').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.missing-image').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li').css('border-left', 'none');
                    $('.menu-container .menu-list ul li.active').css('border-left', 'solid 0.5rem #' + $scope.primary);
                }
                if($scope.widget && $scope.secondary) {
                    $('.primary-btn').css('background-color', '#' + $scope.secondary);
                    $('.border-button.primary').css('color', '#' + $scope.secondary);
                    $('.border-button.primary').css('border', '2px solid #' + $scope.secondary);
                    $('.border-button.primary:hover').css('background-color', '#' + $scope.secondary);
                    $('.border-button.primary').hover(function() {
                        $(this).css('background-color', '#' + $scope.secondary);
                        $(this).css('color', '#FFFFFF');
                    }, function() {
                        $(this).css('background-color', '#FFFFFF');
                        $(this).css('color', '#' + $scope.secondary);
                    });
                }
                if($scope.widget && $scope.tertiary) {
                    $('.completed-animation').css('background-color', '#' + $scope.tertiary);
                    $('.completed-animation-circle p').css('color', '#' + $scope.tertiary);
                }
            });
        }

        function init() {
            $scope.stepsCount = $scope.steps.length;

            $scope.lesson.pages.forEach(function callback(currentValue, index, array) {
                if(array[index].sys.contentType.sys.id.toLowerCase() === "videopage") {
                    // Add subtitles to videos
                    filterTextFactory.addSubtitlesToVideo(array[index].fields.videoEmbedCode).then(function(video) {
                        array[index].fields.videoEmbedCode = video;
                    });
                }
            });

            // Check what page the user is on, if they are returning to this lesson
            //if(cookieFactory.isLocalStorage()){ $scope.currIdx = parseInt(localStorage.getItem($scope.lesson.lessonId)); } else { $scope.currIdx = parseInt(cookieFactory.getCookie($scope.lesson.lessonId)); }

            $scope.currIdx = $location.search().page;

            // If the page hasn't been set yet, OR if the current page is outside the possible page length, set it to 0
            if(!$scope.currIdx || $scope.currIdx >= $scope.stepsCount) {
                $scope.currIdx = 0;
            }

            slidesFactory.setCurrentIndex($scope.currIdx);

            $scope.selectStep($scope.currIdx);

            $scope.closeFrame = dataTransferFactory.get('applicationCloseFrame');
            $scope.stringify = dataTransferFactory.get('stringify');

            if($scope.widget && $scope.primary) {
                updateColors();
                $(window).resize(function () {
                    updateColors();
                });
            }

            dataTransferFactory.set('contentTitle', $scope.lesson.name);

            /*if($scope.stringify) {
                window.parent.postMessage(JSON.stringify({event: 'widgetReady', closeWindow: false, completed: false, stringy: true}), "*");
            }
            else {
                window.parent.postMessage({event: 'widgetReady', closeWindow: false, completed: false}, "*");
            }*/
        }

        init();
    };

    angular.module('monjApp')
        .controller('LessonController', ['$scope', '$stateParams', '$location', '$timeout', 'slidesFactory', 'autoScrollFactory', '$state', '$sce', 'filterTextFactory', 'dataTransferFactory', 'userFactory', 'userInit', 'factoryInit', 'colorInit', LessonController]);

}());
