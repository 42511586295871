(function() {

    var PowerUpController = function ($scope, $timeout, $translate, $location, $stateParams, dataTransferFactory, userFactory, powerupInit, userInit, $state, filterFactory) {
        $scope.user = userInit;
        $scope.powerUps = powerupInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'PowerUpController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        // Check to see if the user was routed from a challenge, and if so, grab the ID data passed in
        /*$scope.challengeData = dataTransferFactory.get('challengeData');
        // Remove challenge data once it's been retrieved. User should only see challenge data if routed from their team challenge page
        dataTransferFactory.remove('challengeData');*/

        var chalObj = $scope.user.userMonj.challenge;
        if(chalObj) {
            if(chalObj.daysLeftToEnroll <= 0 && chalObj.daysUntilChallengeEnds > 0 && chalObj.team.teamId) {
                var validIds = [];
                var ruleExists = false;
                for(var x = 0; x < chalObj.team.teamRules.length; x++) {
                    if(chalObj.team.teamRules[x].contentType == "powerup") {
                        ruleExists = true;
                        validIds = validIds.concat(chalObj.team.teamRules[x].validContentIds);
                    }
                }
                for(var x = 0; x < chalObj.team.individualRules.length; x++) {
                    if(chalObj.team.individualRules[x].contentType == "powerup") {
                        ruleExists = true;
                        validIds = validIds.concat(chalObj.team.individualRules[x].validContentIds);
                    }
                }
            }
            if(ruleExists) {
                $scope.challengeData = validIds;
            }
        }

        $scope.waiting = false;

        $scope.powUpsData = [];
        $scope.completedPowerUps = [];

        /***********************************
         * Filter Factory methods used by view
         */
        $scope.transparentItems = {};
        $scope.filteredTags = {};
        $scope.completedPowerUps = filterFactory.updateCompleted($scope.user.userMonj.completedPowerUpIds);

        $scope.filterClick = function(evt, clearAllSiblings) {
            filterFactory.filterClick(evt, clearAllSiblings);
            $scope.transparentItems = filterFactory.getTransparentItems();
            $scope.filteredTags = filterFactory.getFilteredTags();
        };

        $scope.filterClear = function(evt, clearTag) {
            filterFactory.filterClear(evt, clearTag);
            $scope.transparentItems = filterFactory.getTransparentItems();
        };

        $scope.itemsFilter = function(item, index, array) {
            $scope.transparentItems = filterFactory.getTransparentItems();
            return filterFactory.itemsFilter(item, index, array, $('.item-grid'));
        };

        /**
         * End Filter Factory Methods
         ********************************************/

        function init() {

            userFactory.getPowerUpTopics($scope.user.userMonj.userId).then(function(data) {
                $scope.filters = data;
            });


            userFactory.getPowerUps($scope.user.userMonj.userId).then(function(powerups) {
                $scope.powUpsData = powerups;
            });

            if($stateParams.powerupId) {
                userFactory.viewPowerUp($scope.user.userMonj.userId, $stateParams.powerupId);
            }

            // Loop through the powerups object and create tags to match the ID's used in the view
            for(var x = 0; x < $scope.powerUps.length; x++) {
                $scope.powerUps[x].tags = [];
                $scope.powerUps[x].tags.push($scope.powerUps[x].topicId.toString());

                // If challenge data exists, append the challenge tag to the appropriate items
                if($scope.challengeData) {
                    var chalLength = $scope.challengeData.length;
                    if(chalLength == 0) {
                        $scope.powerUps[x].tags.push('challenge');
                    } else {
                        for (var i = 0; i < chalLength; i++) {
                            if ($scope.powerUps[x].powerUpId == $scope.challengeData[i]) {
                                $scope.powerUps[x].tags.push('challenge');
                            }
                        }
                    }
                }
            }

            // Reset previously selected filters
            filterFactory.clearFilteredTags();

            // Check for heights of recipe labels after data loads
            $timeout(function() {
                filterFactory.updateLabelHeights($('.item-grid'));
            });

            var evt = [];
            if($scope.challengeData) {
                filterFactory.setFilteredTag('challenge', 'Challenge');
                evt.currentTarget = "#challenge";
                $scope.filterClick(evt);
            }
        };

        init();

        $scope.completePowUp = function() {
            $scope.answerBox = $('#answer-text-form')[0];
            userFactory.completePowerUp($scope.user.userMonj.userId, $scope.pUp.monjId, $scope.answerBox.value).then(function(data) {
                // Trigger awards flipper and close modal
                $('.flip-container').addClass('flipped');
                $('.flip-container .badge-item').addClass('show');
                $scope.user.widgetAwardData = data.outcome;
                $('.modal-background').addClass('hide');
                $('.popup-modal').removeClass('active');
                $location.path($state.href('powerUpViewWidget', {powerupId: $stateParams.powerupId}).replace('#', ''));
            });
        };

        $( window ).resize(function() {
            filterFactory.updateLabelHeights($('.item-grid'));
        });

        $scope.redirect = function (powerUpId) {
            $scope.waiting = true;
            $location.path($state.href('powerUpView', {powerupId: powerUpId}).replace('#', ''));
        };

        $scope.isMobile = function() {
            return $(window).width() <= 768;
        };

        $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
            if(!($scope.user.userMonj.touredPowerUps)){
                $scope.startTour();
            }
        });

        $scope.startTour = function() {
            var tourPlacement = ["right"];
            if($scope.isMobile()){
                tourPlacement = ["bottom"];
            }

            // Perform Additional Translations for tour
            var gotIt = '';
            var powerUps = '';
            var powerUpsDesc = '';
            $translate('tour.gotIt').then(function(translation) {
                gotIt = translation;
            });
            $translate('tour.powerUps').then(function(translation) {
                powerUps = translation;
            });
            $translate('tour.powerUpsDesc').then(function(translation) {
                powerUpsDesc = translation;
            });

            $timeout(function() {
                var tour = new Tour({
                    name: 'tour',
                    onShown: function(tourN){
                        tourN._options.autoscroll = false;
                    },
                    onStart: function() {
                        if($('.hamburger-menu-icon')) {
                            $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                        }
                    },
                    onEnd: function() {
                        if($('.hamburger-menu-icon')) {
                            $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                        }
                    },
                    steps: [
                        {
                            element: "#img0",
                            title: powerUps,
                            content: powerUpsDesc,
                            placement: tourPlacement[0],
                            backdropContainer: "#backdrop-container",
                            backdrop: true,
                            onShow: function(tour) {
                                $('#img0').addClass('temp-offset');
                                $('#backdrop-container').addClass('visible-overflow');
                            },
                            onHide: function(tour) {
                                tour._options.autoscroll = true;
                                $('#img0').removeClass('temp-offset');
                                $('#backdrop-container').removeClass('visible-overflow');
                                userFactory.updateTouredPages("powerups", $scope.user.userMonj.userId);
                            },
                            template: "<div class='popover tour'><div class='arrow index-mobile'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='end'>"+ gotIt +"</div></div>",
                        }
                    ]});

                localStorage.removeItem("tour_current_step");
                localStorage.removeItem("tour_end");

                // Initialize the tour
                tour.init();

                // Start the tour
                tour.start();
            });
        };
    };

    angular.module('monjApp')
        .controller('PowerUpController', ['$scope', '$timeout', '$translate', '$location', '$stateParams', 'dataTransferFactory', 'userFactory', 'powerupInit', 'userInit', '$state', 'filterFactory', PowerUpController]);

}());
