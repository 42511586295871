/**
 * Created by Jared Beckerman on 4/20/2017.
 */

(function () {

    var autoScrollFactory = function ($timeout) {
        var factory = {};

        factory.scrollTo = function(id, handle) {
            var scrollY = 0;
            $timeout(function() {
                if ($('#' + id)[0]) {
                    scrollY = $('#' + id)[0].offsetTop - 48;
                }
                if(handle) {
                    $('#' + handle).animate({scrollTop: scrollY}, 600);
                } else {
                    $('html, body').animate({scrollTop: scrollY}, 600);
                }
            }, 200);
        };

        factory.scrollTop = function(handle) {
            if(handle) {
                $('#' + handle).animate({scrollTop: 0}, 100);
            } else {
                $('html, body').animate({ scrollTop: 0 }, 100);
            }
        };

        return factory;
    };

    angular.module('monjApp').factory('autoScrollFactory', ['$timeout', autoScrollFactory]);

}());
