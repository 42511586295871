(function() {

    var ChallengeController = function ($scope, $timeout, $translate, $location, $sce, $q, userInit, teamsInit, dataTransferFactory, userFactory, $state) {
        $scope.wasRedirected = false;
        $scope.showMessage = false;
        $scope.userSelected = true;
        $scope.noChallengeAvailable = true;
        $scope.teamFind = false;
        $scope.completed = false;
        $scope.user = userInit;
        $scope.waiting = false;
        $scope.errorMsg = {};

        $scope.teamName = '';
        $scope.challengeStartDate = '';

        // Log this page view for analytics.
        analytics.page({
            title: 'ChallengeController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

    	function init() {

            if($scope.user.userMonj.challenge) {
                // Format start and end dates
                $scope.challengeStartDate = moment($scope.user.userMonj.challenge.challengeStartDate);
                $scope.challengeEndDate = moment($scope.user.userMonj.challenge.challengeEndDate);

                // test the state of the current challenge and assign it to user.userMonj.challenge.state
                var now = moment(new Date()); //today's date
                var startTime = moment.duration($scope.challengeStartDate.diff(now)); // Calculate the diff between the dates
                var endTime = moment.duration($scope.challengeEndDate.diff(now)); // Calculate the diff between the dates
                if(startTime.asDays() >= 0) {
                    $scope.user.userMonj.challenge.state = 0;
                } else if(endTime.asDays() < 0) {
                    $scope.user.userMonj.challenge.state = 2;
                } else {
                    $scope.user.userMonj.challenge.state = 1;
                }

                $scope.challengeStartDate = moment($scope.challengeStartDate).format('l');

            }
    	}

        // If the user has joined a challenge, reroute them to their challenge team page
        if($scope.user.userMonj.challenge) {
          if($scope.user.userMonj.challenge.team.teamId > 0) {
            $scope.waiting = true;
            $location.path($state.get('teamChallenge').url);
          }
        }

    	   init();

        $scope.assignToTeam = function() {
          if(!($scope.user.userMonj.challenge.teamId) && teamsInit.length > 0) {
            userFactory.assignToOpenTeam($scope.user.userMonj.challenge.challengeId, $scope.user.userMonj.userId).then(function() {
              $scope.teamName = $scope.user.userMonj.challenge.team.teamName;
              var d = new Date($scope.user.userMonj.challenge.challengeStartDate);
              var options = {
                  weekday: "long", year: "numeric", month: "short",
                  day: "numeric", hour: "2-digit", minute: "2-digit"
              };
              $scope.challengeStartDate = d.toLocaleTimeString("en-us", options);
              $scope.openFunPopup('assign-to-team-popup-container');
                $timeout(function() {
                    $scope.takeToTeam();
                }, 3000);
            });
          } else {
            $scope.openModal('no-teams-to-join-popup-container');
          }
        };

        $scope.goToTeamChallengePage = function() {
          $scope.waiting = true;
          $location.path($state.get('teamChallenge').url);
        };

        $scope.createTeam = function(name) {
            $scope.waiting = true;
            $('.create-a-team-input').removeClass('error');
            if(!(name) || name == '') {
              $scope.errorMsg.visible = true;
              $scope.waiting = false;
              $('.create-a-team-input').addClass('error');
              $translate('challenge.nameTaken').then(function(data) {
                  $scope.errorMsg.msg = data;
              });
              return;
            }
            $scope.errorMsg.visible = false;
            userFactory.createTeam($scope.user.userMonj.challenge.challengeId, name, $scope.user.userMonj.userId).then(function(data) {
                if(data) {
                    if(data.errorCode) {
                        $scope.errorMsg.visible = true;
                        $scope.waiting = false;
                        $('.create-a-team-input').addClass('error');
                        $translate('challenge.nameTaken').then(function(data) {
                            $scope.errorMsg.msg = data;
                        });
                    } else {
                        $scope.waiting = false;
                        $scope.teamName = $scope.user.userMonj.challenge.team.teamName;
                        var d = new Date($scope.user.userMonj.challenge.challengeStartDate);
                        var options = {
                            weekday: "long", year: "numeric", month: "short",
                            day: "numeric", hour: "2-digit", minute: "2-digit"
                        };
                        $scope.challengeStartDate = d.toLocaleTimeString("en-us", options);

                        $scope.closeModal();
                        $scope.openFunPopup('created-team-popup-container');
                        $timeout(function() {
                            $scope.takeToTeam();
                        }, 3000);
                    }
                } else {

                    $scope.waiting = false;
                    $scope.teamName = $scope.user.userMonj.challenge.team.teamName;
                    var d = new Date($scope.user.userMonj.challenge.challengeStartDate);
                    var options = {
                        weekday: "long", year: "numeric", month: "short",
                        day: "numeric", hour: "2-digit", minute: "2-digit"
                    };
                    $scope.challengeStartDate = d.toLocaleTimeString("en-us", options);

                    $scope.closeModal();
                    $scope.openFunPopup('created-team-popup-container');
                    $timeout(function() {
                        $scope.takeToTeam();
                    }, 3000);
                }
            });
        };

        $scope.redirectTo = function (url) {
            $location.path($state.get(url).url);
        };

        $scope.openModal = function(className, delay) {
            $timeout(function() {
                jQuery.event.trigger('pauseVideo');
                $('.' + className).addClass('active');
                $('.modal-background').removeClass('hide');
            }, Math.max(delay | 0, 0));
        };

        $scope.openFunPopup = function(className, delay) {
            $timeout(function() {
                $('.' + className).addClass('show');
                $('.awards-background').removeClass('hide');
                $('.elements-explosion').addClass('active');
            }, Math.max(delay | 0, 0));

            $('.completed-animation').addClass('active');
            $('.completed-animation-circle.' + className).addClass('active');
            $('.completed-animation-circle.' + className + ' .completed-animation-elements').addClass('active');
        };

        $scope.closeModal = function(delay) {
            $timeout(function() {
              if($scope.copied == 1) {
                  $translate('copyToClipboard').then(function(translation) {
                      $("#copy-button")[0].innerHTML = translation;
                  });
                $scope.copied = 0;
              }

                $('.popup-modal').removeClass('active');
                $('.modal-background').addClass('hide');
            }, Math.max(delay | 0, 0));
        };

        $scope.closeAndOpenCreate = function() {
          $scope.closeModal();
          $scope.openModal('create-a-team-container', 300);
        };

        $scope.takeToTeam = function() {
          $scope.waiting = true;
          $scope.redirectTo('teamChallenge');
        };

        $scope.joinTeamButton = function () {
          if(!($scope.user.userMonj.challenge.team.teamId) && teamsInit.length > 0) {
                $scope.waiting = true;
                $scope.redirectTo('joinChallenge');
          } else {
            $scope.openModal('no-teams-to-join-popup-container');
          }
        };

    };

    angular.module('monjApp')
        .controller('ChallengeController', ['$scope', '$timeout', '$translate', '$location', '$sce', '$q',
                            'userInit', 'teamsInit', 'dataTransferFactory', 'userFactory', '$state', ChallengeController]);

}());
