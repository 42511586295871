(function() {

    var ChallengeJoinController = function ($scope, $timeout, $location, userFactory, userInit, teamsInit, $state) {
        $scope.user = userInit;
        $scope.teams = teamsInit;

        $scope.teamName;

        // Log this page view for analytics.
        analytics.page({
            title: 'ChallengeJoinController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        // If the user is not a member of the specified team, redirect them to the challenge page
        if($scope.user.userMonj.challenge.team.teamId) {
            $location.path($state.get('teamChallenge').url);
        }

        $scope.waiting = false;

        function init() {
        }

        init();

        $scope.redirectTo = function (url) {
            $location.path($state.get(url).url);
        };

        $scope.openModal = function(className, delay) {
            $timeout(function() {
                jQuery.event.trigger('pauseVideo');
                $('.' + className).addClass('active');
                $('.modal-background').removeClass('hide');
            }, Math.max(delay | 0, 0));
        };

        $scope.openFunPopup = function(className, delay) {
            $('.completed-animation').addClass('active');
            $('.completed-animation-circle').addClass('active');
            $('.completed-animation-elements').addClass('active');
        };

        $scope.closeModal = function(delay) {
            $timeout(function() {
              if($scope.copied == 1) {
                  $translate('copyToClipboard').then(function(translation) {
                      $("#copy-button")[0].innerHTML = translation;
                  });
                $scope.copied = 0;
              }

                $('.popup-modal').removeClass('active');
                $('.modal-background').addClass('hide');
            }, Math.max(delay | 0, 0));

            if($scope.user.userMonj.challenge.team) {
              $scope.waiting = true;
              $location.path($state.get('teamChallenge').url);
            }
        };

        $scope.takeToTeam = function() {
          $scope.waiting = true;
          $scope.redirectTo('teamChallenge');
        };

        $scope.joinTeam = function (team) {
            $scope.waiting = true;

            userFactory.joinATeam(team.teamId, $scope.user.userMonj.userId).then(function (result) {
                $scope.waiting = false;

                $scope.teamName = $scope.user.userMonj.challenge.team.teamName;
                var d = new Date($scope.user.userMonj.challenge.challengeStartDate);
                var options = {
                    weekday: "long", year: "numeric", month: "short",
                    day: "numeric", hour: "2-digit", minute: "2-digit"
                };
                $scope.challengeStartDate = d.toLocaleTimeString("en-us", options);

                $scope.openFunPopup('join-team-popup-container');
                $timeout(function() {
                    $scope.takeToTeam();
                }, 3000);
            });
        };
    };

    angular.module('monjApp')
        .controller('ChallengeJoinController', ['$scope', '$timeout', '$location', 'userFactory', 'userInit', 'teamsInit', '$state', ChallengeJoinController]);

}());
