(function() {

    var widgetController = function ($scope, $translate, $timeout, userFactory, $state, dataTransferFactory) {
        $scope.user = userFactory.getUserData();

        $scope.widget = true;

        var d = new Date();
        var weekday = new Array(7);

        if($scope.user.userMonj.locale == 'en'){
            weekday[0] =  "Sunday";
            weekday[1] = "Monday";
            weekday[2] = "Tuesday";
            weekday[3] = "Wednesday";
            weekday[4] = "Thursday";
            weekday[5] = "Friday";
            weekday[6] = "Saturday";
        } else {
            weekday[0] =  "Domingo";
            weekday[1] = "Lunes";
            weekday[2] = "Martes";
            weekday[3] = "Mi\xE9rcoles";
            weekday[4] = "Jueves";
            weekday[5] = "Viernes";
            weekday[6] = "S\xE1bado";
        }

        $scope.day = weekday[d.getDay()];

        function init() {

            if($state.current.name == 'dailyMissionWidget') {
                userFactory.getDailyMission($scope.user.userMonj.userId)
                //userFactory.DEVgetMission($scope.user.userMonj.userId, 30)
                //userFactory.DEVgetPoll($scope.user.userMonj.userId, 1)
                    .then(function (result) {
                        if (result) {
                            $scope.mission = result;
                        }

                        if(result.content) {
                            switch(result.content.type) {
                                case 'quiz':
                                    $translate('headerList.mission.quiz').then(function (translation) {
                                        $scope.mission.content.pollName = translation;
                                    });
                                    break;
                                case 'research':
                                    $translate('headerList.mission.survey').then(function (translation) {
                                        $scope.mission.content.pollName = translation;
                                    });
                                    break;
                                case 'outcomes':
                                    $translate('headerList.mission.checkingIn').then(function (translation) {
                                        $scope.mission.content.pollName = translation;
                                    });
                                    break;
                                default:
                                    $scope.mission.content.pollName = '';
                                    break;
                            }
                        }

                        // Record that this mission has been viewed
                        if (result.content && result.contentType == "mission") {
                            userFactory.postViewMission($scope.user.userMonj.userId, result.content.missionId);
                        }

                        // Record that this poll has been viewed
                        if (result.content && result.contentType == "poll") {
                            userFactory.postViewPoll($scope.user.userMonj.userId, $scope.mission.content.pollId);
                        }

                        // dynamically set height for back of mission card
                        $scope.missionInit = false;

                        calcMissionHeights();

                        if($scope.mission.content.imageUrl) {
                            //$timeout(function() {
                            // Ensure the image, if it exists, is appended to the flip-image class
                            $('.flipper .flip-img').css('background-image', "url('" + $scope.mission.content.imageUrl + "?w=767&h=445&fm=jpg&q=80')");
                            //},1000);
                        }
                    });
            }

            $scope.closeFrame = dataTransferFactory.get('applicationCloseFrame');
            $scope.stringify = dataTransferFactory.get('stringify');

            /*if($scope.stringify) {
                window.parent.postMessage(JSON.stringify({event: 'widgetReady', closeWindow: false, completed: false, stringy: true}), "*");
            }
            else {
                window.parent.postMessage({event: 'widgetReady', closeWindow: false, completed: false}, "*");
            }*/
        }

        init();

        function calcMissionHeights() {
            $('#ae-mission-container .flipper').height('100vh');
        }

        $scope.missionFlipToggle = function() {
            //calculateMissionHeights();
            if($scope.FLIPPED_MISSION) {
                $scope.FLIPPED_MISSION = false;
                $('#ae-mission-container').removeClass('flipped');
            } else {
                $scope.FLIPPED_MISSION = true;
                $('#ae-mission-container').addClass('flipped');
            }
            //calcMissionHeights();
        };

        $scope.saveDailyMission = function() {
            if($scope.mission.content) {
                if (!$scope.mission.content.isSaved) {
                    $scope.mission.content.isSaved = true;
                    userFactory.postSaveMission($scope.user.userMonj.userId, $scope.mission.content.missionId);
                    var convertedMissionData = [{
                        'timestamp': new Date().toLocaleString(),
                        'contentId': $scope.mission.content.missionId,
                        'contentType': 'Mission',
                        'contentTypeEnum': 1,
                        'contentName': $scope.mission.content.title,
                        'body': $scope.mission.content.body,
                        'imageUrl': $scope.mission.content.imageUrl,
                        'gameValue': $scope.mission.content.gameValue,
                        'isComplete': $scope.mission.isComplete
                    }];
                    dataTransferFactory.set('lastSavedMission', convertedMissionData);
                } else {
                    // Otherwise, unsave the mission since the user hit ACCEPT a second time.
                    $scope.unsaveMission($scope.mission.content.missionId);
                    dataTransferFactory.remove('lastSavedMission');
                }
            }
        };

        // Unsave mission when the user clicks the ACCEPT  button a second time.
        $scope.unsaveMission = function(savedMissionId) {
            if($scope.mission.content.isSaved) {
                $scope.mission.content.isSaved = null;

                if(savedMissionId) {
                    userFactory.postUnsaveMission($scope.user.userMonj.userId, savedMissionId);
                }
            }
        };

        $scope.completeDailyMission = function(send_api_request) {

            if (!$scope.mission.isComplete) {
                $scope.mission.isComplete = true;
                if (send_api_request && $scope.mission.content) {
                    userFactory.postCompleteMission($scope.user.userMonj.userId, $scope.mission.content.missionId).then(function() {
                        $timeout(function () {
                            $('.completed-animation').removeClass('active');
                            $scope.missionFlipToggle();
                        }, 5000);
                    });
                    if ($scope.closeFrame) {
                        $timeout(function () {
							if($scope.stringify) {
								window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: true, stringy: true}), "*");
                                window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: true, stringy: true}), "*");
							}
							else {
								window.parent.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: true}, "*");
                                window.ReactNativeWebView.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: true}, "*");
							}
                            $('.completed-animation').removeClass('active');
                            $scope.missionFlipToggle();
                        }, 5000);
                    }
                }
            }
        };

        $scope.selectedPollAnswer = null;

        $scope.selectPollAnswer = function(index) {
            $scope.selectedPollAnswer = index;
        };

        $scope.convertIndexToLetter = function(index) {
            return String.fromCharCode(65 + index);
        };

        $scope.submitPollAnswer = function(index) {
            if(!$scope.mission.isComplete) {
                $scope.mission.isComplete = true;
                userFactory.postAnswerPoll($scope.user.userMonj.userId, $scope.mission.content.pollId, $scope.selectedPollAnswer);
            }

            $timeout(function() {
                calcMissionHeights();
            }, 1000);
        };
    };

    angular.module('monjApp')
        .controller('widgetController', ['$scope', '$translate', '$timeout', 'userFactory', '$state', 'dataTransferFactory', widgetController]);

}());
