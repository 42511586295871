(function() {

    var videosController = function ($scope, $timeout, $translate, $location, $stateParams, dataTransferFactory, userFactory, filterTextFactory, $sce, userInit, videoInit) {
        $scope.user = userInit;
        $scope.currVideo = videoInit.fields;
        $scope.waiting = false;

        // Add subtitles to video
        filterTextFactory.addSubtitlesToVideo($scope.currVideo.videoEmbedCode).then(function(video) {
            $scope.currVideo.videoEmbedCode = video;
        });

        /*************************************************
         * filterTextFactory methods for use in view
         */

        $scope.flowplayerFilter = function(embedCode) {
            return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
        };


        $scope.updateProtocols = function(original) {
            return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
        };

        /**
         * End filterTextFactory methods
         *************************************************/

        function init() {
        };

        init();

        $('#share').on("focus", function(e) {
            e.target.select();
            $(e.target).one('mouseup', function(e) {
                e.preventDefault();
            });
        });
    };

    angular.module('monjApp')
        .controller('videosController', ['$scope', '$timeout', '$translate', '$location', '$stateParams',
            'dataTransferFactory', 'userFactory', 'filterTextFactory', '$sce', 'userInit', 'videoInit', videosController]);

}());
