(function() {

    var RecipeCollectionsController = function ($scope, $location, userFactory, factoryInit, dataTransferFactory, $state) {
        $scope.user = userFactory.getUserData();

        function init() {

            //$scope.collections = recipesFactory.getCollections();
            //$scope.recipes = recipesFactory.getRecipes();
        }

        init();

        $scope.changeSelection = function (collection) {
          var idx = $scope.collections.indexOf(collection);
          dataTransferFactory.set('collectionIdx', idx);
          dataTransferFactory.set('wasSelected', true);

          if ($scope.user.authData) {
            $scope.user.userMonj.currentCollectionId = collection.id;
          }

          $location.path($state.get('publicExperience').url);
        }

        $scope.redirectTo = function (url) {
          $location.path($state.get(url).url);
        }
    };

    angular.module('monjApp')
      .controller('RecipeCollectionsController', ['$scope', '$location', 'userFactory',
                                   'factoryInit', 'dataTransferFactory', '$state', RecipeCollectionsController]);

}());
