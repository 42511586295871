(function () {

    var ExpandedLessonController = function ($scope, $stateParams, $location, $timeout, slidesFactory, autoScrollFactory, $state, $sce, filterTextFactory, dataTransferFactory, userFactory, awardsFactory, userInit, factoryInit, colorInit, animateSavedButtonFactory) {
        $scope.user = userInit;
        $scope.lesson = factoryInit.fields;
        $scope.colors = colorInit;
        dataTransferFactory.set('completedMessage', $scope.lesson.completedMessage);

        $scope.contentfuiId = factoryInit.sys.id;

        // Log this page view for analytics.
        analytics.page({
            title: 'ExpandedLessonController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        // Add subtitles to video
        filterTextFactory.addSubtitlesToVideo($scope.lesson.overviewVideo).then(function(video) {
            $scope.lesson.overviewVideo = video;
        });

        $scope.saved = $scope.lesson.isSaved;
        $scope.monjLessonData = $scope.lesson;
        if($scope.lesson.pages) {
            $scope.monjLessonData.pageCount = $scope.lesson.pages.length;
        }
        $scope.primary = false;
        $scope.secondary = false;
        $scope.tertiary = false;

        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        function getHex(value) {
            if(value.length > 6) {
                value = value.replace('rgba', '');
                value = value.replace('rgb', '');
                value = value.replace('(', '');
                value = value.replace(')', '');
                value = value.split(',');

                return componentToHex(parseInt(value[0])) + componentToHex(parseInt(value[1])) + componentToHex(parseInt(value[2]));
            }

            return value;
        }

        if($scope.colors) {
            if($scope.colors.primary) {
                $scope.primary = getHex($scope.colors.primary.replace('#', ''));
            }
            if($scope.colors.secondary) {
                $scope.secondary = getHex($scope.colors.secondary.replace('#', ''));
            }
            if($scope.colors.tertiary) {
                $scope.tertiary = getHex($scope.colors.tertiary.replace('#', ''));
            }
        }

        var page = dataTransferFactory.get('page');
        if(page >= 0) {
            $scope.monjLessonData.resumePage = page;
        }
        if(page == 'no-resume') {
            $scope.monjLessonData.resumePage = null;
        }

        $location.search('page', null);

        if (!$stateParams.id || !$scope.lesson) {
            $location.path($state.get('page404').url);
            return;
        }

        if($state.current.name == 'expandedLessonWidget') {
            $scope.widget = true;
        }

        /*************************************************
         * filterTextFactory methods for use in view
         */

        $scope.filterText = function(text) {
            if(text) {
                return filterTextFactory.filterText(text);
            }
            return null;
        };

        $scope.flowplayerFilter = function(embedCode) {
            return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
        };

        $scope.updateProtocols = function(original) {
            return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
        };

        /**
         * End filterTextFactory methods
         *************************************************/

        $scope.goBack = function () {
            if($scope.widget) {
				if($scope.stringify) {
					window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: false, stringy: true}), "*");
                    window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: false, stringy: true}), "*");
				}
				else {
					window.parent.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: false}, "*");
                    window.ReactNativeWebView.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: false}, "*");
				}
            } else {
                $location.path($state.href('expandedLesson', {lessonId: $stateParams.lessonId}).replace('#', ''));
            }
        };

        $scope.beginLesson = function(page) {
            $location.search({'page': page});
            if($scope.widget) {
                if(page){
                    $location.path($state.href('guidedLessonWidget', {id: $stateParams.id}).replace('#', ''));
                }
                $location.path($state.href('guidedLessonWidget', {id: $stateParams.id}).replace('#', ''));
            } else {
                if(page){
                    $location.path($state.href('guidedLesson', {id: $stateParams.id}).replace('#', ''));
                }
                $location.path($state.href('guidedLesson', {id: $stateParams.id}).replace('#', ''));
            }
        };

        $scope.saveLesson = function() {
            $('.my-menu-header .ae-header-container').addClass('show-header');
            if (!$scope.saved) {
                $scope.saved  = true;
                animateSavedButtonFactory.animateOnSave();
                userFactory.saveLesson($scope.user.userMonj.userId, $scope.monjLessonData.lessonId);
                var convertedLessonData = [{
                    'timestamp': new Date().toLocaleString(),
                    'contentId': $scope.monjLessonData.lessonId,
                    'contentType': 'Lesson',
                    'contentTypeEnum': 0,
                    'contentName': $scope.monjLessonData.name,
                    'body': null,
                    'imageUrl': $scope.monjLessonData.thumbUrl,
                    'gameValue': $scope.monjLessonData.gameValue
                }];
                dataTransferFactory.set('lastSavedLesson', convertedLessonData);
            } else {
                $scope.saved  = false;
                $('.action-container .action-icon.save.selected').css('transform', 'translate(0px, 0px)');
                userFactory.unsavelesson($scope.user.userMonj.userId, $scope.monjLessonData.lessonId);
                dataTransferFactory.remove('lastSavedLesson');
            }
        };

        if($state.current.name == 'guidedLessonWidget') {
            $scope.widget = true;
        }

        $scope.completeLesson = function() {
            $scope.waiting = true;
            userFactory.completelesson($scope.user.userMonj.userId, $stateParams.id).then(function() {
                $scope.waiting = false;
                if($scope.widget) {
                    $timeout(function () {
						if($scope.stringify) {
							window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed',*/closeWindow: true, completed: true, stringy: true}), "*");
                            window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed',*/closeWindow: true, completed: true, stringy: true}), "*");
						}
						else {
							window.parent.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: true}, "*");
                            window.ReactNativeWebView.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: true}, "*");
						}
                        $('.completed-animation').removeClass('active');
                        //$location.path($state.get('authExperience').url);
                        $location.url($location.path());
                    }, 3000);
                }
            });
        };

        function init() {
            // Tell Monj the user has viewed the lesson
            userFactory.viewlesson($scope.user.userMonj.userId, $stateParams.id);

            if(dataTransferFactory.get('awardIssued')) {
                dataTransferFactory.remove('awardIssued');
                awardsFactory.checkForAwards();
            }

            $scope.closeFrame = dataTransferFactory.get('applicationCloseFrame');
            $scope.stringify = dataTransferFactory.get('stringify');

            if(dataTransferFactory.get('primaryColorChange')) {
                $scope.primary = dataTransferFactory.get('primaryColorChange');
            }
            if(dataTransferFactory.get('secondaryColorChange')) {
                $scope.secondary = dataTransferFactory.get('secondaryColorChange');
            }
            if(dataTransferFactory.get('tertiaryColorChange')) {
                $scope.tertiary = dataTransferFactory.get('tertiaryColorChange');
            }

            // Update colors if they have been included and is a widget
            $timeout(function() {
                if($scope.widget && $scope.primary) {
                    $('.slider-container .slider-menu').css('background-color', '#' + $scope.primary);
                    $('.drilldown > header').css('background-color', '#' + $scope.primary);
                    $('.secondary-btn').css('background-color', '#' + $scope.primary);
                }
                if($scope.widget && $scope.secondary) {
                    $('.primary-btn').css('background-color', '#' + $scope.secondary);
                    $('.border-button.primary').css('color', '#' + $scope.secondary);
                    $('.border-button.primary').css('background-color', '#ffffff');
                    $('.border-button.primary').css('border', '2px solid #' + $scope.secondary);
                    $('.border-button.primary').hover(function() {
                        $(this).css('background-color', '#' + $scope.secondary);
                        $(this).css('color', '#FFFFFF');
                    }, function() {
                        $(this).css('background-color', '#FFFFFF');
                        $(this).css('color', '#' + $scope.secondary);
                    });
                }
                if($scope.widget && $scope.tertiary) {
                    $('.completed-animation').css('background-color', '#' + $scope.tertiary);
                    $('.completed-animation-circle p').css('color', '#' + $scope.tertiary);
                }
            });

            /*if($scope.stringify) {
                window.parent.postMessage(JSON.stringify({event: 'widgetReady', closeWindow: false, completed: false, stringy: true}), "*");
            }
            else {
                window.parent.postMessage({event: 'widgetReady', closeWindow: false, completed: false}, "*");
            }*/
        }

        init();
    };

    angular.module('monjApp')
        .controller('ExpandedLessonController', ['$scope', '$stateParams', '$location', '$timeout', 'slidesFactory', 'autoScrollFactory', '$state', '$sce', 'filterTextFactory', 'dataTransferFactory', 'userFactory', 'awardsFactory', 'userInit', 'factoryInit', 'colorInit', 'animateSavedButtonFactory', ExpandedLessonController]);

}());
