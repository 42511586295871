/******************************************
 * AWARDS ANIMATION
 ******************************************
 *
 * Check the actions outcome to determine if awards should be displayed
 * If an award needs to be displayed, it first displays it, then updates
 * the user profile after the animation completes.
 *
 * outcome: The outcome object passed from the action call
 * profile: The user profile object passed from the action call
 * animateExperience: boolean which decides whether or not to show an animation for experience gains
 * animateHeader: boolean which decides whether or not to animate in the header element (for pages outside of the authExperience)
 *
 * Created by Jared Beckerman on 6/30/2017.
 */

(function () {

    var awardsFactory = function ($timeout, $translate, $state, $location) {
        var factory = {};
        var badgeLoopIndex = 0;

        var newLevel = null;
        var completedMessage = null;

        var isWidget = false;
        var route = $state.current.name;
        if(route === 'guidedLessonWidget' || route === 'expandedLessonWidget' || route === 'guidedRecipeWidget' ||
            route === 'expandedRecipeWidget' || route === 'powerUpViewWidget' || route === 'essentialsWidget' ||
            route === 'dailyMissionWidget') {
            isWidget = true;
        }

        factory.getNewLevel = function() {
            return newLevel;
        };

        factory.getCompletedMessage = function() {
            return completedMessage;
        };

        factory.checkForAwards = function(outcome) {
            if(outcome) {
                completedMessage = outcome.pointsMessage;

                if (outcome.disallowed) {
                    $('.completed-animation').addClass('active');
                    $('.completed-animation .add-action-not-allowed').addClass('active');
                } else {
                    // Only displayed if the outcome is allowed. (The outcome is disallowed if the user attempts to complete multiple items within the allowed time-limits
                    if (outcome.levelAwarded) {
                        newLevel = outcome.newLevel;
                        $('.completed-animation').addClass('active');
                        $('.completed-animation .add-level').addClass('active');
                        $timeout(function () {
                            initiateCompletedBadgeAndUGCSequence(outcome);
                        }, 1000);
                    } else {
                        $('.completed-animation').addClass('active');
                        initiateCompletedBadgeAndUGCSequence(outcome);
                    }
                }
            }
        };

        function initiateCompletedBadgeAndUGCSequence(outcome) {
            $timeout(function () {
                $('.completed-animation-circle').addClass('active');
                $timeout(function () {
                    $('.completed-animation-elements').addClass('active');
                    $timeout(function() {
                        if(!isWidget && outcome) {
                            // If the awards should be triggered...
                            if (outcome.badgesAwarded || outcome.changedBadges.length > 0) {
                                badgeLoopIndex = 0;
                                triggerBadgeAnimation(outcome);
                            } else {
                                // Otherwise, immediately display the post UGC tools
                                // But, first check to see if there was a badge awarded. If there were no badges awarded, do not display UGC tools, instead, automatically close award animation upon completion.
                                if(outcome.badgesAwarded) {
                                    $('.ugc-post-tools').addClass('active');
                                } else {
                                    if($state.current.name !== 'essentialsWidget' && $state.current.name !== 'powerUpViewWidget' && $state.current.name !== 'expandedRecipeWidget' && $state.current.name !== 'guidedRecipeWidget' && $state.current.name !== 'expandedLessonWidget' && $state.current.name !== 'guidedLessonWidget') {
                                        $timeout(function () {
                                            $('.popup-modal').removeClass('active');
                                            $('.modal-background').addClass('hide');
                                            $('.ugc-post-tools').removeClass('active');
                                            $('.completed-animation').removeClass('active');
                                            if ($state.current.name !== 'authExperience' && $state.current.name !== 'inspiration' && $state.current.name !== 'teamChallenge') {
                                                $location.path($state.get('authExperience').url);   // May need to change this depending on the route. For example, if a user completes a lesson, they may need to be returned to the Lesson page instead of the front page.
                                                $location.url($location.path());
                                            }
                                        }, 1000);
                                    }/* else {
                                        $timeout(function () {
                                            $('.popup-modal').removeClass('active');
                                            $('.modal-background').addClass('hide');
                                            $('.ugc-post-tools').removeClass('active');
                                            $('.completed-animation').removeClass('active');
                                            window.parent.postMessage({event: 'widgetClosed', closeWindow: true, completed: true}, "*");
                                            console.log('window should have closed');
                                            $location.url($location.path());
                                            //if ($state.current.name !== 'authExperience' && $state.current.name !== 'inspiration' && $state.current.name !== 'teamChallenge') {
                                                //$location.path($state.get('authExperience').url);   // May need to change this depending on the route. For example, if a user completes a lesson, they may need to be returned to the Lesson page instead of the front page.

                                            //}
                                        });
                                    }*/
                                }
                            }
                        } else {
                            // If it is a widget, do not show awards. Instead, after the animation, return the user to the previous page.
                        }
                    }, 900);
                }, 600);
            }, 400);
        }

        function createBadge(badge) {
            // Draw the badges manually
            var badgeItem = document.createElement('div');
            badgeItem.className = 'badge-item show';

            // Determine if the badge has been completed or not.
            var color = 'white';
            if(badge.toGoCount <= 0) {
                color = 'filled';
            }

            var placeholder = document.createElement('div');
            placeholder.className = 'badge-placeholder badge-level-shadow ' + color + ' ' + badge.className;

            var awardTitle = document.createElement('p');
            awardTitle.textContent = badge.displayName;

            var award = document.createElement('div');
            award.id = 'award-' + badge.className;
            award.className = 'progress-circle';
            //award.setAttribute('elem-ready', "createProgressBar(" + badge.percentComplete + ", '#award-" + badge.className + "', 12, 16)");

            var count = document.createElement('p');
            if(badge.toGoCount <= 0) {
                $translate('lessons.completed').then(function (translation) {
                    count.textContent = translation;
                });
            } else {
                $translate('header.togo', {togo: badge.toGoCount}).then(function (translation) {
                    count.textContent = translation;
                });
            }

            placeholder.appendChild(award);
            badgeItem.appendChild(awardTitle);
            badgeItem.appendChild(placeholder);
            badgeItem.appendChild(count);
            return badgeItem;
        }

        function createProgressBar(percentage, elemName, trailWidth, strokeWidth, trailColor, color) {
            color = color ? color : '#30C2D9';
            trailColor = trailColor ? trailColor : '#ffffff';
            trailWidth = trailWidth ? trailWidth : '8';
            strokeWidth = strokeWidth ? strokeWidth : '8';

            var from = Math.min(strokeWidth, 10);
            var to = Math.min(strokeWidth, 8);

            bar = new ProgressBar.Circle(elemName, {
                color: color,
                trailColor: trailColor,
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: strokeWidth,
                trailWidth: trailWidth,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { width: from },
                to: { width: to },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', circle._opts.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    //var value = Math.round(circle.value());
                }
            });
            bar.animate(percentage);
        };

        function triggerBadgeAnimation(outcome) {
            $timeout(function() {
                if(document.getElementById('awards')) {
                    document.getElementById('awards').classList.add('active');
                    document.getElementById('awards').appendChild(createBadge(outcome.changedBadges[badgeLoopIndex]));
                }

                // Choose the progress bar colors
                var color = '#30C2D9';
                if(outcome.changedBadges[badgeLoopIndex].toGoCount <= 0) {
                    switch (outcome.changedBadges[badgeLoopIndex].className) {
                        case 'wellness-badge':
                            color = '#ff9e00';
                            break;
                        case 'knife1-badge':
                            color = '#ff9e00';
                            break;
                        case 'social-badge':
                            color = '#1ea182';
                            break;
                        case 'simmer-badge':
                            color = '#1ea182';
                            break;
                        case 'dinner-badge':
                            color = '#1ea182';
                            break;
                        case 'lessons-6-badge':
                            color = '#1ea182';
                            break;
                        case 'curious-badge':
                            color = '#ff4040';
                            break;
                        case 'roast-badge':
                            color = '#ff4040';
                            break;
                        case 'breakfast-badge':
                            color = '#ff4040';
                            break;
                        case 'knife2-badge':
                            color = '#ff4040';
                            break;
                        case 'lessons-5-badge':
                            color = '#ff4040';
                            break;
                        case 'mindful-badge':
                            color = '#ffc411';
                            break;
                        case 'grill-badge':
                            color = '#ffc411';
                            break;
                        case 'lunch-badge':
                            color = '#ffc411';
                            break;
                        case 'lessons-2-badge':
                            color = '#ffc411';
                            break;
                    }
                }

                createProgressBar( outcome.changedBadges[badgeLoopIndex].percentComplete, '#award-' + outcome.changedBadges[badgeLoopIndex].className, 12, 16, '#ffffff', color);

                badgeLoopIndex++;
                if(badgeLoopIndex < outcome.changedBadges.length) {
                    triggerBadgeAnimation(outcome);
                    return;
                }

                if(badgeLoopIndex === outcome.changedBadges.length) {
                    // If user gained a level, remove the level popup at this point
                    $('.completed-animation .add-level').addClass('pop-out');
                    $timeout(function (){
                        $('.completed-animation .add-level').removeClass('active');
                        $('.completed-animation .add-level').removeClass('pop-out');
                    }, 400);

                    // Replace badges with UGC tools 3 seconds after they pop-in to view.
                    $timeout(function() {
                        // After animation is complete, wait two in a half seconds, hide the badges and display post tools
                        document.getElementById('awards').classList.remove('active');
                        $('.ugc-post-tools').addClass('active');
                    }, 3000);
                }
            }, 400);
        }

        return factory;
    };

    angular.module('monjApp').factory('awardsFactory', ['$timeout', '$translate', '$state', '$location', awardsFactory]);

}());
