(function () {

    var GuidedRecipeController = function ($scope, $stateParams, $location, $timeout, $sce, filterTextFactory, slidesFactory, autoScrollFactory, $state, userInit, factoryInit, dictionaryInit, colorInit, dataTransferFactory, userFactory) {
        $scope.user = userInit;
        $scope.recipe = factoryInit.fields;
        $scope.colors = colorInit;

        dataTransferFactory.set('completedMessage', $scope.recipe.completedMessage);

        // Log this page view for analytics.
        analytics.page({
            title: 'GuidedRecipeController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        if (!$stateParams.recipeId || !$scope.recipe) {
            $location.path($state.get('page404').url);
            return;
        }

        $scope.currIdx = false;
        $scope.currStep = [];
        $scope.openDictionary = false;
        $scope.openIngredients = false;
        $scope.keyboardPressed = false;
        $scope.waiting = false;

        $scope.primary = false;
        $scope.secondary = false;
        $scope.tertiary = false;

        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        function getHex(value) {
            if(value.length > 6) {
                value = value.replace('rgba', '');
                value = value.replace('rgb', '');
                value = value.replace('(', '');
                value = value.replace(')', '');
                value = value.split(',');

                return componentToHex(parseInt(value[0])) + componentToHex(parseInt(value[1])) + componentToHex(parseInt(value[2]));
            }

            return value;
        }

        if($scope.colors) {
            if($scope.colors.primary) {
                $scope.primary = getHex($scope.colors.primary.replace('#', ''));
            }
            if($scope.colors.secondary) {
                $scope.secondary = getHex($scope.colors.secondary.replace('#', ''));
            }
            if($scope.colors.tertiary) {
                $scope.tertiary = getHex($scope.colors.tertiary.replace('#', ''));
            }
        }

        if($state.current.name == 'guidedRecipeWidget') {
            $scope.widget = true;
        }

        /************************************************
         * sliderFactory methods - Used to connect back-end functionality with the view
         */

        $scope.dictionary = slidesFactory.setTerms(dictionaryInit);
        $scope.steps = slidesFactory.setSteps($scope.recipe.steps);

        $scope.selectStep = function(index) {
            var shouldAnimate = Math.abs($scope.currIdx - index);
            if(shouldAnimate > 1) {
                // Stop slide animation before switching pages
                $('.slider-container .slider-content').css('transition', 'none');
                $timeout(function() {
                    // Re-enable slide animation after switching pages
                    $('.slider-container .slider-content').css('transition', 'all 400ms ease');
                }, 400);
            }

            // Update slides
            $scope.currStep = slidesFactory.selectStep(index);
            $scope.currIdx = slidesFactory.getCurrentIndex();
            // Custom fields
            $scope.isFinalStep = $scope.currStep[2] == undefined;
            $scope.isNote = ($scope.currStep[1].type !== 'video' && $scope.currStep[1].type !== 'image');

            updateColors();
            userFactory.advanceMeal($scope.user.userMonj.userId, $stateParams.recipeId, $scope.currIdx);
        };

        $scope.openTerm = function (elemOrig, hrefID) {
            $scope.termIdx = slidesFactory.openTerm(elemOrig, hrefID);
            $scope.openDictionary = true;
            $scope.toggleSliderMenu();
            autoScrollFactory.scrollTo('term-' + $scope.termIdx, 'dictionary');
        };

        /**
         * End sliderFactory methods
         ************************************************/

        $scope.nextStep = function() {
            $scope.selectStep(slidesFactory.getCurrentIndex() + 1);
        };
        $scope.prevStep = function() {
            $scope.selectStep(slidesFactory.getCurrentIndex() - 1);
        };

        /**************************************************
         * Touch Navigation Directive Tools
         */
        $(document).unbind('selectNextStep').bind('selectNextStep', function () {
            $scope.nextStep();
            $scope.$apply();
        });
        $(document).unbind('selectPreviousStep').bind('selectPreviousStep', function () {
            $scope.prevStep();
            $scope.$apply();
        });
        /**
         * End Touch Navigation Directive Tools
         ************************************************/

        /*************************************************
         * filterTextFactory methods for use in view
         */

        $scope.filterText = function(text) {
            if(text) {
                return filterTextFactory.filterText(text);
            }
            return null;
        };

        $scope.flowplayerFilter = function(embedCode) {
            return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
        };

        $scope.updateProtocols = function(original) {
            return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
        };

        /**
         * End filterTextFactory methods
         *************************************************/

        $scope.openIEList = function () {
            $scope.openIngredients = true;

            $scope.toggleSliderMenu();
            autoScrollFactory.scrollTop('ingredients');
        };

        document.onkeydown = function(evt) {
            if(!$scope.keyboardPressed) {
                $scope.keyboardPressed = true;
                evt = evt || window.event;
                switch (evt.keyCode) {
                    case 37:
                        $scope.prevStep();
                        break;
                    case 39:
                        $scope.nextStep();
                        break;
                }
            }
            $timeout(function() {
                $scope.keyboardPressed = false;
            }, 200);
        };

        $scope.toggleSliderMenu = function(delay) {
            delay = delay ? delay : 0;
            $timeout(function() {
                if(!$scope.openMenu) {
                    $scope.openMenu = true;
                } else {
                    $scope.openMenu = false;
                    $scope.openDictionary = false;
                    $scope.openIngredients = false;
                }
            }, delay);
        };

        $scope.completeMeal = function() {
            $scope.isFinalPage = true;
            $scope.isFinalStep = false;
            $scope.mealCompleted = true;

            if($scope.widget) {
                $scope.waiting = true;
                userFactory.postCookMeal($scope.user.userMonj.userId, $scope.recipe.recipeId).then(function(data) {
                    $scope.waiting = false;
                    $timeout(function () {
                        if ($scope.closeFrame) {
							if($scope.stringify) {
								window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: true, stringy: true}), "*");
                                window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: true, stringy: true}), "*");
							}
							else {
								window.parent.postMessage({/* event: 'widgetClosed', */closeWindow: true, completed: true }, "*");
                                window.ReactNativeWebView.postMessage({/* event: 'widgetClosed', */closeWindow: true, completed: true }, "*");
							}
                            //$timeout(function() {
                                $scope.goBack(true);
                            //}, 400);
                        } else {
                            $scope.goBack(true);
                        }
                    }, 3000);
                });
            }
        };

        $scope.goBack = function (completeRecipe) {
            if($scope.widget) {
                if($scope.closeFrame) {
					if($scope.stringify) {
						window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: completeRecipe, stringy: true}), "*");
                        window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: completeRecipe, stringy: true}), "*");
					}
					else {
						window.parent.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: completeRecipe}, "*");
                        window.ReactNativeWebView.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: completeRecipe}, "*");
					}
                } else {
                    $location.path($state.href('expandedRecipeWidget', {recipeId: $stateParams.recipeId}).replace('#', ''));
                }
            } else {
                $location.path($state.href('expandedRecipe', {recipeId: $stateParams.recipeId}).replace('#', ''));
            }
        };

        function updateColors() {
            if(dataTransferFactory.get('primaryColorChange')) {
                $scope.primary = dataTransferFactory.get('primaryColorChange');
            }
            if(dataTransferFactory.get('secondaryColorChange')) {
                $scope.secondary = dataTransferFactory.get('secondaryColorChange');
            }
            if(dataTransferFactory.get('tertiaryColorChange')) {
                $scope.tertiary = dataTransferFactory.get('tertiaryColorChange');
            }

            // Update colors if they have been included and is a widget
            $timeout(function() {
                if($scope.widget && $scope.primary) {
                    $('.slider-menu').css('background-color', '#' + $scope.primary);
                    $('.drilldown > header').css('background-color', '#' + $scope.primary);
                    $('.secondary-btn').css('background-color', '#' + $scope.primary);
                    $('term').css('color', '#' + $scope.primary);
                    $('.link').css('color', '#' + $scope.primary);
                    $('.slider-menu .parent-slider-menu .slider-menu-toggle-icon .steps-icon').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.videopage').css('background-image', 'url("./imgs/play-white-plain.svg")');
                    $('.note').css('background-color', '#' + $scope.primary);
                    if($scope.isNote) {
                        $('.arrows div').css('background-color', '#FFFFFF');
                    } else {
                        $('.arrows div').css('background-color', '#' + $scope.primary);
                    }
                    if($( document ).width() <= 768) {
                        $('.arrows div').css('background-color', '#FFFFFF');
                    }
                    //$('.quiz .question-number .number-border').css('background-color', '#' + $scope.primary);
                    $('.quiz .question-number').css('color', '#FFFFFF');
                    $('.quiz ul li.active .question-number').css('color', '#' + $scope.primary);
                    //$('.quiz ul li.active .question-number').css('background-color', '#FFFFFF');
                    $('.menu-container').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.questionpage').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.tippage').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.missing-image').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li .step-icon.tip').css('background-color', '#' + $scope.primary);
                    $('.menu-container .menu-list ul li').css('border-left', 'none');
                    $('.menu-container .menu-list ul li.active').css('border-left', 'solid 0.5rem #' + $scope.primary);
                }
                if($scope.widget && $scope.secondary) {
                    $('.primary-btn').css('background-color', '#' + $scope.secondary);
                    $('.border-button.primary').css('color', '#' + $scope.secondary);
                    $('.border-button.primary').css('border', '2px solid #' + $scope.secondary);
                    $('.border-button.primary').hover(function() {
                        $(this).css('background-color', '#' + $scope.secondary);
                        $(this).css('color', '#FFFFFF');
                    }, function() {
                        $(this).css('background-color', '#FFFFFF');
                        $(this).css('color', '#' + $scope.secondary);
                    });
                }
                if($scope.widget && $scope.tertiary) {
                    $('.completed-animation').css('background-color', '#' + $scope.tertiary);
                    $('.completed-animation-circle p').css('color', '#' + $scope.tertiary);
                }
            });
        }

        function init() {
            // Cycle through steps and update its type
            $scope.recipe.steps.forEach(function callback(currentValue, index, array) {
                switch(currentValue.sys.contentType.sys.id) {
                    case 'overviewStep':
                        array[index].type = 'overview';
                        break;
                    case '6tDgPHudBmMck4iqWQMa4w':
                        array[index].type = 'tip';
                        break;
                    case '4WoT1BbzfOKqGGAmycygcW':
                        array[index].type = 'image';
                        break;
                    case '3oyvoZ3YZWmueKmE0mcE62':
                        array[index].type = 'video';

                        // Add subtitles to videos
                        filterTextFactory.addSubtitlesToVideo(array[index].fields.videoEmbedCode).then(function(video) {
                            array[index].fields.videoEmbedCode = video;
                        });
                        break;
                    case '7ITjgn8IoMMwuScUWCCMyw':
                        array[index].type = 'flavor';
                        break;
                }
            });

            $scope.stepsCount = $scope.steps.length;

            // Check what page the user is on, if they are returning to this recipe
            //if(cookieFactory.isLocalStorage()){ $scope.currIdx = parseInt(localStorage.getItem($scope.recipe.recipeId)); } else { $scope.currIdx = parseInt(cookieFactory.getCookie($scope.recipe.recipeId)); }

            // If the page hasn't been set yet, OR if the current page is outside the possible page length, set it to 0
            if(!$scope.currIdx || $scope.currIdx >= $scope.stepsCount) {
                $scope.currIdx = 0;
            }

            slidesFactory.setCurrentIndex($scope.currIdx);

            $scope.closeFrame = dataTransferFactory.get('applicationCloseFrame');
            $scope.stringify = dataTransferFactory.get('stringify');
            if($scope.widget && $scope.primary) {
                updateColors();
                $(window).resize(function () {
                    updateColors();
                });
            }

            $scope.selectStep($scope.currIdx);

            dataTransferFactory.set('contentTitle', $scope.recipe.title);

            /*if($scope.stringify) {
                window.parent.postMessage(JSON.stringify({event: 'widgetReady', closeWindow: false, completed: false, stringy: true}), "*");
            }
            else {
                window.parent.postMessage({event: 'widgetReady', closeWindow: false, completed: false}, "*");
            }*/
        }

        init();
    };

    angular.module('monjApp')
        .controller('GuidedRecipeController', ['$scope', '$stateParams', '$location','$timeout', '$sce', 'filterTextFactory', 'slidesFactory', 'autoScrollFactory', '$state', 'userInit', 'factoryInit', 'dictionaryInit', 'colorInit', 'dataTransferFactory', 'userFactory', GuidedRecipeController]);

}());
