(function () {

    var CompletedAnimationController = function ($scope, $timeout, userFactory, userInit) {
        $scope.user = userInit;

        function init() {
        }

        $scope.triggerCompleteAnimation = function() {
            $('.completed-animation').addClass('active');
            $timeout(function () {
                $('.completed-animation-circle').addClass('active');
                $timeout(function () {
                    $('.completed-animation-elements').addClass('active');
                    $timeout(function() {
                        //$('.completed-animation').removeClass('active');
                        //$('.ugc-post-tools').addClass('active');
                        //userFactory.completelesson($scope.user.userMonj.userId, $stateParams.id);
                        //dataTransferFactory.set('awardIssued', true);
                    }, 1500);
                }, 600);
            }, 400);
        }

        init();
    };

    angular.module('monjApp')
        .controller('CompletedAnimationController', ['$scope', '$timeout', 'userFactory', 'userInit', CompletedAnimationController]);

}());
