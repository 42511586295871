(function() {

    var LanguageSwitchController = function ($scope, $rootScope, $translate, userFactory, dataTransferFactory) {

        $scope.user = userFactory.getUserData();

        /*var isTouch = (ionic.Platform.isWebView() || ionic.Platform.isIOS() || ionic.Platform.isIPad()
        || ionic.Platform.isAndroid() || ionic.Platform.isWindowsPhone());*/
        var isTouch = Modernizr.touch;

        /*function init() {
            var user = userFactory.getUser().userMonjPromise;
            if(user.authData) {
                user.then(function (data) {
                    $rootScope.userId = data.userId;
                });
            }
        }

        init();*/

        $scope.changeLanguage = function (langKey) {
            $translate.use(langKey);
            $rootScope.lang = language;
            
            if(isLocalStorage()){
                localStorage.setItem("defaultLanguage", langKey);
            } else {
                setCookie('defaultLanguage', langKey, 7);
            }
            
            if($scope.user){
                if($scope.user.userMonj) {
                    userFactory.updateLocale($scope.user.userMonj.userId, langKey).then(function() {
                        location.reload();
                    });
                }
            }
        };

        function isLocalStorage(){
            try {
                localStorage.setItem('localStorageTest', 'test');
                localStorage.removeItem('localStorageTest');
                return true;
            } catch(e) {
                return false;
            }
        }

        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        $rootScope.$on('$translateChangeSuccess', function (event, data) {
            var language = data.language;
            $rootScope.lang = language;
            //$rootScope.userId = userFactory.getUser();
        });
    };

    angular.module('monjApp')
        .controller('LanguageSwitchController', ['$scope', '$rootScope', '$translate', 'userFactory', 'dataTransferFactory', LanguageSwitchController]);

}());
