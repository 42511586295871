(function () {

    var UserController = function ($scope, $translate, $state, $stateParams, $location, userFactory, $location, dataTransferFactory, $sce, $state, $q) {
        //$scope.user = userFactory.getUser();
        $scope.errorMsg = { visible: false, msg: "", isPlain: true };
        $scope.waiting = false;
        $scope.isRecipe = false;
        $scope.showPassResetBtn = true;
        $scope.signupCalled = false;

        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        function init () {

            if ($state.current.name != "signUpPublic") {
                $scope.workEmail = dataTransferFactory.get('workEmail');
                $scope.email = $scope.workEmail ? $scope.workEmail : dataTransferFactory.get('lastUsedEmail');
                // Update email field with email if one exists.
                if($scope.email) {
                    $('#email').val($scope.email);
                }
            }

            $scope.company = dataTransferFactory.get('company');
            $scope.isSSO = dataTransferFactory.get('isSSO');
            if ($scope.company) {
                $scope.companyName = $scope.company.companyName;
            }

            if(!window.navigator.standalone) {
                $('#email').focus();
            }

            // get all translated error messages
            $scope.ermsg = [];
            $translate('errorMsgs.incorrectPass').then(function (translation) {
                $scope.ermsg[0] = translation;
            });
            $translate('errorMsgs.emailInUse').then(function (translation) {
                $scope.ermsg[1] = translation;
            });
            $translate('errorMsgs.passTooShort').then(function (translation) {
                $scope.ermsg[2] = translation;
            });
            $translate('errorMsgs.passNoMatch').then(function (translation) {
                $scope.ermsg[3] = translation;
            });
            $translate('errorMsgs.accountExpired').then(function (translation) {
                $scope.ermsg[4] = translation;
            });
            $translate('errorMsgs.emailNotFound').then(function (translation) {
                $scope.ermsg[5] = translation;
            });
            $translate('errorMsgs.validEmail').then(function (translation) {
                $scope.ermsg[6] = translation;
            });
            $translate('errorMsgs.missingFields').then(function (translation) {
                $scope.ermsg[7] = translation;
            });
            $translate('errorMsgs.emailNotFound2').then(function (translation) {
                $scope.ermsg[8] = translation;
            });
            $translate('errorMsgs.invalidEmail2').then(function (translation) {
                $scope.ermsg[9] = translation;
            });
            $translate('errorMsgs.cannotCreateAccount').then(function (translation) {
                $scope.ermsg[10] = translation;
            });
            $translate('errorMsgs.somethingWentWrong').then(function (translation) {
                $scope.ermsg[11] = translation;
            });
            $translate('errorMsgs.noAccount').then(function (translation) {
                $scope.ermsg[12] = translation;
            });

            $scope.userRegistration = dataTransferFactory.get('generalUserRegistration');
        }

        init();

        $scope.syncUser = function () {
            return userFactory.getUser();
        };

        // Update the user profile when an action is called.
        $scope.updateProfile = function(data) {
            $scope.user = data;
        };

        $scope.login = function (email, password) {
            removeStoredPopupData();
            $scope.waiting = true;
            if(email && password) {
                userFactory.login(email, password)
                    .then(function () {
                        $scope.syncUser().then(function(result) {
                            /*if(result.userMonj) {
                                dataTransferFactory.set('oldUserBadges', result.userMonj.badges);
                            }*/
                            if(result.userMonjPromise) {
                                result.userMonjPromise.then(function() {
                                    userFactory.postSignin(result.userMonj.userId);
                                });
                            } else {
                                userFactory.postSignin(result.userMonj.userId);
                            }

                            var recipeId = dataTransferFactory.get('recipeId');

                            if(!(result.userMonj.touredCustomize)) {
                                document.location.hash = '#/signup-confirmed';
                                $location.path($state.get('signUpConfirmed').url);
                            } else if ($state.current.name == "loginChallenge") {
                                dataTransferFactory.set("redirectToChallenge", true);
                                $location.path($state.get('challenge').url);
                            } else if (recipeId) {
                                dataTransferFactory.remove('recipeId');
                                $location.path($state.href('expandedRecipe', {recipeId: recipeId}).replace('#', ''));
                            } else {
                                $location.path($state.get('authExperience').url);
                            }
                        }, function (error) {
                            $scope.waiting = false;
                            $scope.errorMsg.msg = $scope.ermsg[2];
                            $scope.errorMsg.isPlain = true;
                            $scope.errorMsg.visible = true;
                        });
                    }, function (error) {
                        $scope.waiting = false;
                        if(error == "noAccount") {
                            $scope.errorMsg.msg = $scope.ermsg[12];
                        } else {
                            $scope.errorMsg.msg = $scope.ermsg[0];
                        }
                        //$scope.errorMsg.msg = "Oh no! Password is not correct.";
                        $scope.errorMsg.isPlain = true;
                        $scope.errorMsg.visible = true;
                    });
            } else {
                $scope.errorMsg.msg = $scope.ermsg[7];

                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
                $scope.waiting = false;
            }

        };


        $scope.logout = function () {
            userFactory.logout();
        };

        $scope.signUp = function (inviteCode, activationCode, email, password, workEmail, company, name, isSoleraMDPP) {
            // Prevent call from occuring twice (mobile specific bug)
            if(!$scope.signupCalled) {
                $scope.signupCalled = true;
                userFactory.signUp(inviteCode, activationCode, email, password, workEmail, company, name)
                    .then(function () {
                        $scope.syncUser();
						if(isSoleraMDPP) {
							// HARD CODE for Solera MDPP participants:
							// don't enter Monj site, instead
							// go to dedicated landing site 
							window.location = "https://smartbites.monj.com";
						}
						else {
                            document.location.hash = '#/signup-confirmed';
                        	$location.path($state.get('signUpConfirmed').url);
						}
                    }, function (error) {
                        $scope.signupCalled = false;
                        /*$scope.errorMsg.msg = error.message;
                        $scope.errorMsg.isPlain = true;
                        $scope.errorMsg.visible = true;*/
                        $scope.waiting = false;

                        switch (error.message) {
                            case "found":
                                $scope.errorMsg.msg = $scope.ermsg[1];
                                //$scope.errorMsg.msg = "Sorry! That email address is already in use. Please try another.";
                                $scope.errorMsg.isPlain = true;
                                $scope.errorMsg.visible = true;
                            case "in use":
                                $scope.errorMsg.msg = $scope.ermsg[1];
                                //$scope.errorMsg.msg = "Sorry! That email address is already in use. Please try another.";
                                $scope.errorMsg.isPlain = true;
                                $scope.errorMsg.visible = true;
                                break;
                            case "server":
                                $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[10]);
                                //$scope.errorMsg.msg = $sce.trustAsHtml("<p>Sorry, but we could not create your account. Try again, or <a ui-sref=\"contact\">contact us</a> for help.</p>");
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                                break;
                            default:
                                $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[10]);
                                //$scope.errorMsg.msg = $sce.trustAsHtml("<p>Sorry, but we could not create your account. Try again, or <a ui-sref=\"contact\">contact us</a> for help.</p>");
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                        }
                    });
            }
        };

        // inviteCode, activationCode, email, password, workEmail, company, name
        $scope.continueConversion = function(inviteCode, activationCode, name, emailOrig, pass1, pass2) {
            // isValid returns the company data if it passes
            var isValid = $scope.validatePass(inviteCode, activationCode, name, emailOrig, pass1, pass2);
            // Prevent call from occuring twice (mobile specific bug)
            if(!$scope.signupCalled && isValid) {
                $scope.signupCalled = true;
                userFactory.contCreateUser(inviteCode, activationCode, emailOrig, pass1, $scope.workEmail, isValid, name)
                    .then(function () {
                        $scope.syncUser();
                        $location.path($state.get('signUpConfirmed').url);
                    }, function (error) {
                        $scope.signupCalled = false;
                        $scope.errorMsg.msg = $sce.trustAsHtml(error.message);
                        $scope.errorMsg.isPlain = false;
                        $scope.errorMsg.visible = true;
                        /*switch (error.message) {
                            case "server":
                                $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[10]);
                                //$scope.errorMsg.msg = $sce.trustAsHtml("<p>Sorry, but we could not create your account. Try again, or <a ui-sref=\"contact\">contact us</a> for help.</p>");
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                                break;
                            case "in use": {
                                $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[1]);
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                            }
                            default:
                                $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[10]);
                                //$scope.errorMsg.msg = $sce.trustAsHtml("<p>Sorry, but we could not create your account. Try again, or <a ui-sref=\"contact\">contact us</a> for help.</p>");
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                        }*/

                        $scope.waiting = false;
                    });
            }
        };

        /*$scope.deleteUser = function (email, password) {
            userFactory.deleteUser(email, password)
                .then(function () {
                    $scope.syncUser();
                });
        };*/

        $scope.redirectTo = function (url, id, prev) {
            if (id)
                $location.path($state.href(url, {id: id}).replace('#', ''));
            else if (prev) {
                var prevURL = dataTransferFactory.get('prevPage');
                var parentURL = dataTransferFactory.get('parentPage');
                var currURL = $location.path();

                if (prevURL && prevURL.url && parentURL && currURL != parentURL.url)
                    $location.path($state.get(prevURL.url).url);
                else
                    $location.path($state.get(url).url);
            }
            else
                $location.path($state.get(url).url);
        };

        $scope.registerBtn = function (inviteCode, activationCode, name, emailOrig, pass1, pass2, isSoleraMDPP) {
            var email = regex.test(emailOrig);
            removeStoredPopupData();

            // check if email is already exists or from a registered company
            if (email && !$scope.company) {
                $scope.waiting = true;
                var company = emailOrig.split('@')[1];

                if(!inviteCode) {
                    // Already confirmed company email domain.
                    registerBtnHelper(inviteCode, activationCode, name, emailOrig, pass1, pass2, false);
                    /*userFactory.checkCompanyDomain(company).then(function (data) {
                        if (data.found) {
                            dataTransferFactory.set('checkedCompany', data);
                            dataTransferFactory.set('lastUsedEmail', emailOrig);
                            $scope.companyId = data.companyId;
                            $scope.waiting = false;
                            registerBtnHelper(inviteCode, activationCode, name, emailOrig, pass1, pass2);
                        }
                        else {
                            $scope.waiting = false;
                        }
                    });*/
                } else {
                    // If the user provided an invite code...
                    $scope.waiting = false;
                    registerBtnHelper(inviteCode, activationCode, name, emailOrig, pass1, pass2, isSoleraMDPP);
                }
            }
            else {
                $scope.waiting = false;
                registerBtnHelper(inviteCode, activationCode, name, emailOrig, pass1, pass2, false);
            }
        };

        function removeStoredPopupData() {
            dataTransferFactory.remove('generalUserRegistration');
            dataTransferFactory.remove('isSSO');
            dataTransferFactory.remove('whySignUp');
            dataTransferFactory.remove('resetPassword');
        }

        function registerBtnHelper (inviteCode, activationCode, name, emailOrig, pass1, pass2, isSoleraMDPP) {
            var email = regex.test(emailOrig);

            if (name && email && pass1 && pass2) {
                $('#name').removeClass("error");
                $('#email').removeClass("error");
                $('#pass1').removeClass("error");
                $('#pass2').removeClass("error");

                // validatePass returns the company data if it passes
                var isValid = $scope.validatePass(inviteCode, activationCode, name, emailOrig, pass1, pass2);

                if(isValid) {
                    $scope.signUp(inviteCode, activationCode, emailOrig, pass1, $scope.workEmail, isValid, name, isSoleraMDPP);
                }

            } else {
                if (!name)
                    $('#name').addClass("error");
                else
                    $('#name').removeClass("error");

                if (!email)
                    $('#email').addClass("error");
                else
                    $('#email').removeClass("error");

                if (!pass1)
                    $('#pass1').addClass("error");
                else
                    $('#pass1').removeClass("error");

                if (!pass2)
                    $('#pass2').addClass("error");
                else
                    $('#pass2').removeClass("error");

                if (email)
                    $scope.errorMsg.msg = $scope.ermsg[7];
                    //$scope.errorMsg.msg = "Oops! Please fill in all the red fields.";
                else
                    $scope.errorMsg.msg = $scope.ermsg[6];
                    //$scope.errorMsg.msg = "Oops! That email address is not valid.";

                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
            }
        }

        $scope.validatePass = function(inviteCode, activationCode, name, emailOrig, pass1, pass2) {
            if (pass1 == pass2) {
                //if ($scope.waiting == false) {
                    if (pass1.length < 6) {
                        $('#pass1').addClass("error");
                        $('#pass2').addClass("error");

                        $scope.errorMsg.msg = $scope.ermsg[2];
                        //$scope.errorMsg.msg = "Oops! Password too short. Must have at lest 5 characters.";
                        $scope.errorMsg.visible = true;
                        $scope.errorMsg.isPlain = true;
                        $scope.waiting = false;
                        return false;
                    }
                    else {
                        $scope.waiting = true;
                        $scope.errorMsg.visible = false;
                        $scope.errorMsg.msg = "";

                        var comp = true;
                        if(activationCode) {
                            comp = {
                                type: 'sponsored',
                                value: $scope.companyId
                            };
                            $scope.workEmail = $scope.userRegistration.primaryEmail;
                        } else {

                        }

                        return comp;
                    }
                //}
            } else {
                $('#pass1').addClass("error");
                $('#pass2').addClass("error");

                $scope.errorMsg.msg = $scope.ermsg[3];
                //$scope.errorMsg.msg = "The passwords don't match.";
                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
                $scope.waiting = false;
                return false;
            }
        };

        // EDIT: Removed password on initial login for SSO check
        $scope.loginBtn = function (email/*, pass*/) {
            if (!(email/* && pass*/)) {
                if (!email)
                    $('#email').addClass("error");
                else
                    $('#email').removeClass("error");

                /*if (!pass)
                    $('#pass').addClass("error");
                else
                    $('#pass').removeClass("error");*/
            } else {
                $('#email').removeClass("error");
                //$('#pass').removeClass("error");

                if ($scope.waiting == false) {
                    $scope.waiting = true;
                    userFactory.checkUserByPrimaryEmail(email).then(function(response) {
                        if (response.found && !response.expired) {
                            // Remove error message while waiting to let user know they've succeeded
                            $scope.errorMsg.msg = "";
                            $scope.errorMsg.visible = false;
                            //$scope.emailFound = false;

                            if(response.ssoEnabled){
                                $scope.waiting = false;
                                $scope.ssoEnabled = true;
                                $scope.ssoUrl = response.ssoUrl;
                                $scope.ssoCompanyName = response.companyName;
                                //$location.path('/signup-sponsored');
                            } else {
                                // Switch to password form
                                $scope.emailFound = true;
                                $scope.waiting = false;
                                setTimeout(function() {
                                    if(!window.navigator.standalone) {
                                        $('#pass').focus();
                                    }
                                }, 1000);
                            }
                        } else {
                            if(response.expired) {
                                $scope.errorMsg.msg = $scope.ermsg[4];
                                //$scope.errorMsg.msg = "Oh no! Your account has expired."
                            } else {
                                $scope.errorMsg.msg = $scope.ermsg[5];
                                //$scope.errorMsg.msg = "Oh no! Email not found.";
                            }
                            $scope.errorMsg.visible = true;
                            $scope.waiting = false;
                        }
                    }, function (error) {
                        $scope.errorMsg.msg = $scope.ermsg[2];
                        //$scope.errorMsg.msg = "Oops! Something went wrong. Please try again later or contact Monj support.";
                        $scope.waiting = false;
                        $scope.errorMsg.isPlain = true;
                        $scope.errorMsg.visible = true;
                    });
                    // Do not login yet. Check email first, if found, ask for password. Call this function from "Sign In" button on password form.
                    //$scope.login(email, pass);
                }
            }
        };

        $scope.ssoRedirect = function(url) {
            window.location.assign(url);
        };

        $scope.passResetBtn = function (email) {
            if (email) {
                $scope.waiting = true;

                userFactory.checkUserByPrimaryEmail(email).then(function (checkUser) {
                    if (checkUser.found) {
                        userFactory.resetPassword(email).then(function () {
                            $scope.email = email;
                            $scope.waiting = false;
                            $scope.errorMsg.visible = false;
                            $scope.showPassResetBtn = false;
                            $scope.$digest();
                        }, function (error) {
                            // If the user has a monj account (confirmed by checkUserByPrimaryEmail) but no firebase account
                            // confirmed if this route gets hit, then we can assume that they are an SSO user.
                            // Check the users email for SSO info and display it.

                            $scope.waiting = false;
                            if(error.code == "auth/too-many-requests") {
                                $scope.errorMsg.msg = $sce.trustAsHtml(error.message);
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                            } else {
                                $scope.sso = checkUser;
                                $scope.showPassResetBtn = false;
                                $scope.errorMsg.visible = false;
                            }
                            $scope.$digest();

                            /*userFactory.checkCompanyDomain(email).then(function(companyResult) {
                                $scope.waiting = false;
                                $scope.sso = companyResult;
                                $scope.showPassResetBtn = false;
                                $scope.errorMsg.visible = false;
                            }, function(error) {
                                $scope.sso = false;
                                $scope.waiting = false;
                                $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[11]);
                                $scope.errorMsg.isPlain = false;
                                $scope.errorMsg.visible = true;
                            });*/
                        });
                    } else {
                        $scope.waiting = false;
                        $scope.errorMsg.msg = $scope.ermsg[8];
                        //$scope.errorMsg.msg = "Sorry, but we can't find that email address.";
                        $scope.errorMsg.isPlain = true;
                        $scope.errorMsg.visible = true;
                    }
                }, function (error) {
                    $scope.waiting = false;

                    $scope.errorMsg.msg = $sce.trustAsHtml($scope.ermsg[11]);
                    $scope.errorMsg.isPlain = false;
                    $scope.errorMsg.visible = true;
                });
            }
            else {
                $scope.waiting = false;

                $scope.errorMsg.msg = $scope.ermsg[9];
                //$scope.errorMsg.msg = "Oops! Invalid email address.";
                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
            }
        }

    };

    angular.module('monjApp')
        .controller('UserController', ['$scope', '$translate', '$state', '$stateParams', '$location', 'userFactory',
                               '$location', 'dataTransferFactory', '$sce', '$state', '$q', UserController]);

}());
