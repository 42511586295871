(function() {

    var GeneralController = function ($rootScope, $scope, $location, $timeout, $state, userFactory, $translate, appSettings, filterFactory) {

        $scope.currURL = document.URL;

        $scope.waiting = false;
        $scope.goalIsBeingSet = false;

        $scope.shareUrl = $location.absUrl();

        $scope.queryString = "";

        $scope.copied = 0;
        if($scope.user){
            $scope.userLowSalt = $scope.user.userMonj.pref2LowSalt ? $scope.user.userMonj.pref2LowSalt : false;
            $scope.userLowSugar = $scope.user.userMonj.pref2LowSugar ? $scope.user.userMonj.pref2LowSugar : false;
            $scope.userLowCarb = $scope.user.userMonj.pref2LowCarbs ? $scope.user.userMonj.pref2LowCarbs : false;
            $scope.userLowCal = $scope.user.userMonj.pref2LowCalorie ? $scope.user.userMonj.pref2LowCalorie : false;    
            $scope.userNoDairy = $scope.user.userMonj.pref2DairyFree ? $scope.user.userMonj.pref2DairyFree : false;
            $scope.userNoGluten = $scope.user.userMonj.pref2GlutenFree ? $scope.user.userMonj.pref2GlutenFree : false;
            $scope.userNoShellfish = $scope.user.userMonj.pref2ShellfishFree ? $scope.user.userMonj.pref2ShellfishFree : false;
            $scope.userNoSoy = $scope.user.userMonj.pref2SoyFree ? $scope.user.userMonj.pref2SoyFree : false;
            $scope.userNoTreeNuts = $scope.user.userMonj.pref2TreeNutFree ? $scope.user.userMonj.pref2TreeNutFree : false;
            $scope.userNoEggs = $scope.user.userMonj.pref2EggFree ? $scope.user.userMonj.pref2EggFree : false;
            $scope.userNoNightshades = $scope.user.userMonj.pref2NightshadeFree ? $scope.user.userMonj.pref2NightshadeFree : false;
            $scope.userNoCorn = $scope.user.userMonj.pref2CornFree ? $scope.user.userMonj.pref2CornFree : false;
            $scope.userNoGrains = $scope.user.userMonj.pref2GrainFree ? $scope.user.userMonj.pref2GrainFree : false;
            $scope.userNoMeat = $scope.user.userMonj.pref2MeatFree ? $scope.user.userMonj.pref2MeatFree : false;
            $scope.firstName = $scope.user.userMonj.firstName;
            $scope.pathStatement = getUserPrefPath($scope.user,false);
            $scope.anythingGoes =
              !$scope.user.userMonj.pref2LowSalt &&
              !$scope.user.userMonj.pref2LowSugar &&
              !$scope.user.userMonj.pref2LowCarbs &&
              !$scope.user.userMonj.pref2LowCalorie &&
              !$scope.user.userMonj.pref2DairyFree &&
              !$scope.user.userMonj.pref2GlutenFree &&
              !$scope.user.userMonj.pref2ShellfishFree &&
              !$scope.user.userMonj.pref2MeatFree &&
              !$scope.user.userMonj.pref2TreeNutFree
                ? true
                : false;
        }
        var monjApiKey = appSettings.monjApiKey;
        var monjURL = appSettings.monjURL;

        $scope.shareFocus = function(evt) {

        }

        $("#shareInput").focus(function () { this.setSelectionRange(0, 9999); return false; } ).mouseup( function () { return false; });



        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            // Take the user to a different screen here.
            $("#share-icon-container").addClass("no-show");
        }

        $scope.executeCopy = function() {
            var copyButton = $("#copy-button");
            $("#shareInput")[0].select();
            document.execCommand('copy');
            $translate('share.copied').then(function(translation) {
                copyButton[0].innerHTML = translation;
            });
            copyButton.addClass('copied');
            $timeout(function() {
                copyButton.removeClass('copied');
                $translate('copyToClipboard').then(function(translation) {
                    copyButton[0].innerHTML = translation;
                });
            }, 3000);
        };

        $scope.copyInvite = function() {
            var copyButton = $("#copy-button");
            $("#invite")[0].select();
            document.execCommand('copy');
            $translate('share.copied').then(function(translation) {
                copyButton[0].innerHTML = translation;
            });
            copyButton.addClass('copied');
            $timeout(function() {
                copyButton.removeClass('copied');
                $translate('copyToClipboard').then(function(translation) {
                    copyButton[0].innerHTML = translation;
                });
            }, 3000);
        };

        $scope.checkTabs = function() {
            if($('.tab-nav-wrapper') && $('.tab-nav-wrapper')[0]){
                if($('.tab-nav-wrapper')[0].scrollLeft > 0 || $('.tab-nav-wrapper')[0].scrollWidth < $(window).width()) {
                    $('.tab-right-shade').addClass('no-show');
                } else {
                    $('.tab-right-shade').removeClass('no-show');
                }
            }
        };

        $('.tab-nav-wrapper').ready(function(){
            $timeout(function () {
                $scope.checkTabs();
            }, 10);
        });

        $('.tab-nav-wrapper').on("scroll", function() {
            $scope.checkTabs();
        });

        $(window).resize(function() {
            $scope.checkTabs();
        });

        $scope.showDescription = function() {
            $('.page-title-description').toggleClass('active');
        };

        $scope.openModal = function(className, delay) {
            $timeout(function() {
                jQuery.event.trigger('pauseVideo');
                $('.' + className).addClass('active');
                $('.modal-background').removeClass('hide');
            }, Math.max(delay | 0, 0));
        };

        $scope.closeModal = function(delay) {
            $timeout(function() {
                if ($scope.copied == 1) {
                    $translate('copyToClipboard').then(function (translation) {
                        $("#copy-button")[0].innerHTML = translation;
                    });
                    $scope.copied = 0;
                }

                // Pause flowplayer on close.
                var elements = $('.popup-modal .flowplayer');
                if (elements) {
                    var fp = elements.data('flowplayer');
                    if (fp) {
                        fp.pause();
                    }
                }

                $('.popup-modal').removeClass('active');
                $('.modal-background').addClass('hide');
            }, Math.max(delay | 0, 0));
        };

        // This method closes the current modal and loads a new one after 300ms.
        $scope.openModalFromWithinModal = function(className) {
            $scope.closeModal();
            $timeout(function() {
                $scope.openModal(className);
            }, 300);
        };

        $scope.goBack = function () {
            var stateName = $state.current.name;
            if(stateName == 'expandedRecipe') {
                //$location.path('/guided-meals');
                $location.path($state.get('guidedMealsIndex').url);
                //$scope.apply;
            } else {
                window.history.back();
            }
        };

        // Function to switch between tab content.
        // showClass contains the tab ID in which should be displayed. All other tabs will be hidden automatically
        $scope.tabSwitch = function(showID, el) {
            // Switch selected tab-link
            $scope.activeTab(el);

            // Check to see if an ID Hash was included. If not, include one.
            var content = null;
            if(showID.indexOf('#') > -1) {
                content = $(showID);
            } else {
                content = $('#' + showID);
            }

            // Hide all other tab content panes
            $('.tag-tab-wrapper').removeClass('active');
            // Show the specified class
            content.addClass('active');
        };

        // Make clicked tab the active one
        $scope.activeTab = function(evt) {
            // Deactivate all other tabs
            var el = $(evt.currentTarget);

            $('.tab-link').removeClass('active');
            angular.element(evt.currentTarget).parent().siblings().removeClass('active');
            // Activate the selected tab

            angular.element(evt.currentTarget).addClass('active');
            angular.element(evt.currentTarget).parent().addClass('active');
        };

        // Change the global language variable
        $scope.changeLanguage = function(el) {
            var changedValue = el.value;
        };

        $scope.updateGoal = function(groupId, topicId) {

            if(!$scope.goalIsBeingSet) {
                $scope.goalIsBeingSet = true;
                switch(groupId) {
                    case "wisdom":
                        $rootScope.loading = true;
                        if (topicId === $scope.user.userMonj.wisdomGoalTopicId) {
                            userFactory.deleteWisdomGoal($scope.user.userMonj.userId).then(function() {
                                $rootScope.loading = false;
                                $scope.goalIsBeingSet = false;
                                $scope.user.userMonj.wisdomGoalTopicId = null;
                            });
                        } else {
                            userFactory.setWisdomGoal($scope.user.userMonj.userId, topicId).then(function() {
                                $rootScope.loading = false;
                                $scope.goalIsBeingSet = false;
                                $scope.user.userMonj.wisdomGoalTopicId = topicId;
                            });
                        }
                        break;
                    case "methods":
                        $rootScope.loading = true;
                        if (topicId === $scope.user.userMonj.methodGoalTopicId) {
                            userFactory.deleteMethodGoal($scope.user.userMonj.userId).then(function() {
                                $rootScope.loading = false;
                                $scope.goalIsBeingSet = false;
                                $scope.user.userMonj.methodGoalTopicId = null;
                            });
                        } else {
                            userFactory.setMethodGoal($scope.user.userMonj.userId, topicId).then(function() {
                                $rootScope.loading = false;
                                $scope.goalIsBeingSet = false;
                                $scope.user.userMonj.methodGoalTopicId = topicId;
                            });
                        }
                        break;
                    case "powerups":
                        $rootScope.loading = true;
                        if (topicId === $scope.user.userMonj.powerUpGoalTopicId) {
                            userFactory.deletePowerUpGoal($scope.user.userMonj.userId).then(function() {
                                $rootScope.loading = false;
                                $scope.goalIsBeingSet = false;
                                $scope.user.userMonj.powerUpGoalTopicId = null;
                            });
                        } else {
                            userFactory.setPowerUpGoal($scope.user.userMonj.userId, topicId).then(function() {
                                $rootScope.loading = false;
                                $scope.goalIsBeingSet = false;
                                $scope.user.userMonj.powerUpGoalTopicId = topicId;
                            });
                        }
                        break;
                }
            }
        };

        $scope.isSelectedGoal = function(groupId, topicId) {
            if($scope.user.userMonj) {
                switch(groupId) {
                    case "wisdom":
                        if($scope.user.userMonj.wisdomGoalTopicId == topicId){
                            return true;
                        }
                        break;
                    case "methods":
                        if($scope.user.userMonj.methodGoalTopicId == topicId){
                            return true;
                        }
                        break;
                    case "powerups":
                        if($scope.user.userMonj.powerUpGoalTopicId == topicId){
                            return true;
                        }
                        break;
                }
                return false;
            }
        };

        $scope.createProgressBar = function(percentage, elemName, trailWidth, strokeWidth, trailColor, color) {
            color = color ? color : '#30C2D9';
            trailColor = trailColor ? trailColor : '#ffffff';
            trailWidth = trailWidth ? trailWidth : '8';
            strokeWidth = strokeWidth ? strokeWidth : '8';

            var from = Math.min(strokeWidth, 10);
            var to = Math.min(strokeWidth, 8);

            $scope.bar = new ProgressBar.Circle(elemName, {
                color: color,
                trailColor: trailColor,
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: strokeWidth,
                trailWidth: trailWidth,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { width: from },
                to: { width: to },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', circle._opts.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    //var value = Math.round(circle.value());
                }
            });
            $scope.bar.animate(percentage);
        };

        $scope.calcPercentage = function(x, y) {
            if(y < 0) {
                return 1;
            }

            return Math.min((x / y), 1);
        };

        $scope.dietaryPreferenceChange = function() {  
            $rootScope.loading = true;
            $scope.checkAnythingGoes();
            userFactory
              .diseasePrefs(
                $scope.user.userMonj.userId,
                $scope.userLowSalt,
                $scope.userLowSugar,
                $scope.userLowCarb,
                $scope.userLowCal
              )
              .then(function () {
               
                $timeout(function () {
                    $rootScope.loading = false;
                }, 2000);
               
                // $scope.pathStatement = $scope.user.userMonj.pref2Path;
              });
        };

        $scope.allergyPreferenceChange = function () {      
          $rootScope.loading = true;   
          $scope.checkAnythingGoes();
          userFactory
            .allergyPrefs(
              $scope.user.userMonj.userId,
              $scope.userNoDairy,
              $scope.userNoGluten,
              $scope.userNoShellfish,
              $scope.userNoSoy,
              $scope.userNoTreeNuts,
              $scope.userNoEggs,
              $scope.userNoNightshades,
              $scope.userNoCorn,
              $scope.userNoGrains,
              $scope.userNoMeat
            )
            .then(function () {
               
                $timeout(function () {
                    $rootScope.loading = false;
                }, 2000);
              // $scope.pathStatement = $scope.user.userMonj.pref2Path;
            });
        };
        $scope.checkAnythingGoes = function () {
            if (
                $scope.userLowSalt ||
                $scope.userLowSugar ||
                $scope.userLowCarb ||
                $scope.userLowCal ||
                $scope.userNoDairy ||
                $scope.userNoGluten ||
                $scope.userNoShellfish ||
                $scope.userNoTreeNuts ||
                $scope.userNoMeat
              ) {
                $scope.anythingGoes = false;
              }
              else{
                $scope.anythingGoes = true;
              }
        }
        $scope.anythingGoesSelect = function () {
            if ($scope.anythingGoes) {
                $scope.userLowSalt = false;
                $scope.userLowSugar = false;
                $scope.userLowCarb = false;
                $scope.userLowCal = false;
                $scope.userNoDairy = false;
                $scope.userNoGluten = false;
                $scope.userNoShellfish = false;
                $scope.userNoSoy = false;
                $scope.userNoTreeNuts = false;
                $scope.userNoEggs = false;
                $scope.userNoNightshades = false;
                $scope.userNoCorn = false;
                $scope.userNoGrains = false;
                $scope.userNoMeat = false;
                $scope.allergyPreferenceChange();
                $scope.dietaryPreferenceChange();
              }
        };
        
        $scope.clearFilterPreferences = function () {
            filterFactory.clearFilteredTags();
            filterFactory.clearRecipeFilteredTags();
        };

        function getUserPrefPath(user, isOnlyComma = true) {
            let prefTextArr = [];
            if (user.userMonj.pref2LowSalt) {
              prefTextArr.push("reduced salt");
            }
            if (user.userMonj.pref2LowSugar) {
              prefTextArr.push("reduced sugar");
            }
            if (user.userMonj.pref2LowCarbs) {
              prefTextArr.push("reduced carbs");
            }
            if (user.userMonj.pref2LowCalorie) {
              prefTextArr.push("reduced calories");
            }
            if (user.userMonj.pref2DairyFree) {
              prefTextArr.push("dairy free");
            }
            if (user.userMonj.pref2GlutenFree) {
              prefTextArr.push("gluten free");
            }
            if (user.userMonj.pref2ShellfishFree) {
              prefTextArr.push("shellfish free");
            }
            if (user.userMonj.pref2MeatFree) {
              prefTextArr.push("meat free");
            }
            if (user.userMonj.pref2TreeNutFree) {
              prefTextArr.push("treenut free");
            }
            if (isOnlyComma) {
              return prefTextArr.length > 0 ? prefTextArr.join(", ") : "";
            } else {
              let pathWithAnd = "";
              if (prefTextArr.length > 0) {
                for (let index = 0; index < prefTextArr.length; index++) {
                  const element = prefTextArr[index];
                  if (index == prefTextArr.length - 1) {
                    pathWithAnd += pathWithAnd ? " and " : "";
                  } else {
                    pathWithAnd += pathWithAnd ? ", " : "";
                  }
                  pathWithAnd += element;
                }
              }
              if(pathWithAnd==""){
                pathWithAnd= "Anything Goes";
              }
              return pathWithAnd;
            }
          }

    };

    angular.module('monjApp')
        .controller('GeneralController', ['$rootScope','$scope', '$location', '$timeout', '$state', 'userFactory', '$translate', 'appSettings','filterFactory', GeneralController]);

}());
