/**
 * Created by Jared Beckerman on 4/20/2017.
 * Slide factory manages slides and pages for multiple views
 */

(function () {

    var slidesFactory = function ($q, $timeout, $interval, $filter, cookieFactory) {
        var factory = {};

        factory.terms = {};
        factory.steps = {};

        factory.currStep = [];

        factory.currIdx = false;
        factory.termIdx = 0;

        factory.isFinalStep = false;

        /*********************************
         * Getters and Setters for factory fields defined above
         * @param key
         * @param value
         * @param steps - An object containing all steps
         * @param terms - An object containing all terms
         * @param orderBy - The field by which to order the terms (defaults to 'entry')
         */

        factory.setSteps = function(steps) {
            factory.steps = steps;
            return factory.steps;
        };
        factory.getSteps = function() {
            return factory.steps;
        };

        factory.setTerms = function(terms, orderBy) {
            factory.terms = terms;
            orderBy = orderBy ? orderBy : 'entry';

            // Order list of terms alphabetically
            factory.terms = $filter('orderBy')(terms, orderBy);

            return factory.terms;
        };
        factory.getTerms = function() {
            return factory.terms;
        };

        factory.getTermIndex = function() {
            return factory.termsIdx;
        };

        factory.getCurrentIndex = function() {
            return factory.currIdx;
        };
        factory.setCurrentIndex = function(index) {
            factory.currIdx = index;
        };

        factory.getCurrentStep = function() {
            return factory.currStep;
        };

        /**
         * End Getters and Setters
         ***********************************************/


        /***********************************************
         * Updates the step
         */
        function updateStep() {
            if(!factory.steps[parseInt(factory.currIdx) + 1]) {
                factory.currStep = [factory.steps[parseInt(factory.currIdx) - 1], factory.steps[parseInt(factory.currIdx)]];
            } else {
                factory.currStep = [factory.steps[parseInt(factory.currIdx) - 1], factory.steps[parseInt(factory.currIdx)], factory.steps[parseInt(factory.currIdx) + 1]];
            }

            if(factory.currStep[1] && factory.currStep[1].fields && factory.currStep[1].fields.questionId) {
                // Update fields to remove any classname breaking special characters
                // .replace(/[^\w\s]/gi, '').toLowerCase()
                factory.currStep[1].fields.questionId = factory.currStep[1].fields.questionId.replace(/[^\w\s]/gi, '').toLowerCase();
            }

            // Time the delay on the final step to show animation on button
            factory.isFinalStep = factory.currStep[2] === undefined;
            savePosition();

            // Pause the playing video
            var flowplayer = $(".flowplayer.active");
            api = flowplayer.data("flowplayer");
            if(api) {
                api.pause();
            }
        }

        // Store current page index in case user returns to this recipe later
        function savePosition() {
            //if(cookieFactory.isLocalStorage()){ localStorage.setItem(factory.recipe.recipeId, factory.currIdx); } else { cookieFactory.setCookie(factory.recipe.recipeId, factory.currIdx, 7); }
        }

        /***************************************************
         * Selects a specific step
         * @param index
         * @returns {Array|[*,*,*]}
         */
        factory.selectStep = function (index) {
            factory.currIdx = Math.max( Math.min(index, factory.steps.length - 1), 0);
            updateStep();
            $('html, body').animate({ scrollTop: 0 }, 300);

            // Must stop and reset the carousel interval before restarting it.
            jQuery.event.trigger('stopCarousel');
            jQuery.event.trigger('startCarousel');
            jQuery.event.trigger('pauseVideo');

            return factory.currStep;
        };

        factory.openTerm = function (elemOrig, hrefID) {
            if (!elemOrig)
                return;

            var elem = "";
            if(hrefID) {
                elem = encodeURI(hrefID.replace(/\s+/g, " ").replace(/[\\]/g, "").trim().toLowerCase());
            } else {
                elem = encodeURI(elemOrig.target.innerText.replace(/\s+/g, " ").replace(/[\\]/g, "").trim().toLowerCase());
            }

            var filtered = factory.terms.filter(function (e) { return elem == e.value.toLowerCase(); })[0];
            var idx = factory.terms.indexOf(filtered);
            factory.termIdx = idx;

            return factory.termIdx;
        };

        return factory;
    };

    angular.module('monjApp').factory('slidesFactory', ['$q', '$timeout', '$interval', '$filter', 'cookieFactory', slidesFactory]);

}());
