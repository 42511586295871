(function () {

    var contentfulFactory = function ($q, $rootScope, cookieFactory, userFactory) {

        var factory = {};
        var client = null;
        var limit = 1000;
        factory.cache = {};
        factory.vtt = {};

        // Update rootScope to match current language settings before contentful pull
        if(cookieFactory.isLocalStorage()){
            $rootScope.lang = localStorage.getItem("defaultLanguage");
        } else {
            $rootScope.lang = cookieFactory.getCookie('defaultLanguage');
        }

        /*********************************
         * Caching Methods
         **/

        factory.setCache = function (key, value) {
            factory.cache[key] = value;
        };

        factory.appendToCache = function(key, value) {
            // Combine existing values if a previous iteration exists
            if(factory.getCache(key)) {
                value = factory.cache[key].concat(value);
            }
            // Store the new value overriding the old one with the combined data
            factory.setCache(key, value);
        };

        factory.getCache = function (key) {
            return factory.cache[key];
        };

        factory.removeCache = function (key) {
            if (factory.cache[key]) {
                delete factory.cache[key];
            }
        };

        factory.getCmsItem = function(key, id) {
            var allItems = factory.getCache(key);
            if (!allItems) return null;
            //return result[0];
            var result = allItems.filter(function (elem) { return elem.sys.id == id; });
            return result && result.length > 0 ? result : null;
        };

        factory.checkCache = function (key) {
            return (typeof factory.cache[key] != "undefined") ? true : false;
        };

        /**
         * End caching methods
         **********************************/



        /*********************************
         * Initialize Contentful
         **/

        factory.setContentfulClient = function(spaceId, accessToken, host) {
            // Remove http/https formatting from string
            host = host.replace('https://', '');
            host = host.replace('http://', '');

            // Create new client
            client = contentful.createClient({
                space: spaceId,
                accessToken: accessToken,
                host: host,
                secure: true
            });
        };

        /**
         * End contentful initialization
         **********************************/

        /**********************************
         * Get All Contentful Data living within the contentId
         * @param contentId
         * @returns {*|promise|e|t|d}
         */
        factory.getContentfulData = function(contentId, entryId) {
            var deferred = $q.defer();

            // Check if the data has been retrieved, stored in cache and is a single item
            if(entryId) {
                var singleCachedItem = factory.getCmsItem(contentId, entryId);
                if(singleCachedItem) {
                    // single item stored in cache
                    deferred.resolve(singleCachedItem);
                    return deferred.promise;
                }
            }

            var cachedItems = factory.checkCache(contentId);
            // single item not requested and list stored in cache
            if(!entryId && cachedItems) {
                deferred.resolve(factory.getCache(contentId));
                return deferred.promise;
            }

            // If the data has not been cached, query contentful and retrieve it.
            if(client) {
                // Get VTT info to append to flowplayer embed code BEFORE contentful pulls its data
                userFactory.getVTTs().then(function(data) {
                    factory.vtt = data;
                    if(entryId) {
                        // retrieving single item
                        deferred.resolve(grabContent(contentId, 0, null, entryId));
                    } else {
                        // retrieving list of items
                        deferred.resolve(grabContent(contentId));
                    }
                });
            } else {
                // If no client exists, return error message
                console.log('Error: No contentful client has been set. Please ensure your client has been created (via setConentfulClient method) before attempting to retrieve data from contentful.');
                deferred.reject('No contentful client has been set');
            }

            return deferred.promise;
        };

        /*************************************
         * Grabs the content from contentful
         * @param contentId
         * @param skip
         * @param deferred
         * @returns {*|promise|e|t|d}
         */
        // Internal function to recursively grab contentful data
        function grabContent(contentId, skip, deferred, entryId) {
            skip = skip ? skip : 0;
            deferred = deferred ? deferred : $q.defer();
            var spaceContent = {};

            if(entryId) {
                // Grab a single entry within the contentId from Contentful
                spaceContent = {
                    content_type: contentId,
                    'sys.id': entryId,
                    limit: limit,
                    skip: skip,
                    order: '-sys.createdAt',
                    locale: $rootScope.lang
                };

            } else {
                // grab all specified space content from Contentful
                spaceContent = {
                    content_type: contentId,
                    limit: limit,
                    skip: skip,
                    order: '-sys.createdAt',
                    locale: $rootScope.lang
                };
            }

            client.entries(spaceContent).then(function(data) {
                // If not all entries were retrieved, make another call until all data is present.
                if(data.total > limit + skip) {
                    // Append the data to the object
                    factory.appendToCache(spaceContent.content_type, data);
                    // Call to contentful until all data has been received
                    grabContent(spaceContent.content_type, skip + limit, deferred);
                } else {
                    // If it took more than a single query to grab all the data, append the last of the data to our cache
                    if(skip > 0) {
                        factory.appendToCache(spaceContent.content_type, data);
                    } else {
                        // Otherwise, store the data in our cache
                        // factory.setCache(spaceContent.content_type, data);
                        if(entryId && !factory.getCmsItem(contentId, entryId)) {
                            factory.appendToCache(spaceContent.content_type, data);
                        } else {
                            if(entryId && factory.checkCache(contentId)) {
                                factory.appendToCache(spaceContent.content_type, data);
                            } else {
                                factory.setCache(spaceContent.content_type, data);
                            }
                        }
                    }
                    if(entryId) {
                        deferred.resolve(factory.getCmsItem(spaceContent.content_type, entryId));
                    } else {
                        deferred.resolve(factory.getCache(spaceContent.content_type));
                    }
                }
            });

            return deferred.promise;
        }

        function resolvePromises(promises, deferred) {
            var currPromise = promises.pop();
            currPromise[0].then(function (data) {
                // If not all entries were retrieved, make another call until all data is present.
                if(data.total > limit + currPromise[1]) {
                    // Append the data to the object
                    factory.appendToCache(currPromise[2], data);
                    // Call to contentful until all data has been received
                    grabContent(currPromise[2], currPromise[1] + limit);
                } else {
                    // If it took more than a single query to grab all the data, append the last of the data to our cache
                    if(currPromise[1] > 0) {
                        factory.appendToCache(currPromise[2], data);
                    } else {
                        // Otherwise, store the data in our cache
                        factory.setCache(currPromise[2], data);
                    }
                    //deferred.resolve(factory.getCache(currPromise[2]));
                    // Recursively call this method until all promises have been resolved.
                    resolvePromises(promises, deferred);
                }
            }, function (error) {
                Raygun.send(new Error("CONTENTFUL 'resolvePromises()' Error: " + error));
                deferred.reject(error);
            });
        }

        return factory;
    };

    angular.module('monjApp').factory('contentfulFactory', ['$q', '$rootScope', 'cookieFactory', 'userFactory', contentfulFactory]);

}());
