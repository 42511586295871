(function () {
	
	var dataTransferFactory = function () {
		var savedData = {collectionIdx: 0};
		var missionData = {expectedId: null};


		var factory = {};

		factory.set = function (key, value) {
			savedData[key] = value;
		}

		factory.get = function (key) {
			return savedData[key];
		}

		factory.remove = function (key) {
			if (savedData[key]) {
				delete savedData[key];
			}
		}

		factory.check = function (key) {
			return (savedData[key] ? true : false);
		}

		// My Missions
		factory.expectSavedMission = function (missionId) {
			missionData.expectedId = missionId;
		}

		factory.viewExpectedSavedMission = function() {
			return missionData.expectedId;
		}

		factory.clearExpectedSavedMission = function() {
			missionData.expectedId = null;
		}

		return factory;
	}

    angular.module('monjApp').factory('dataTransferFactory', dataTransferFactory);
                               
}());