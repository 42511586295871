(function() {

    var MiscPagesController = function ($scope, $state, $stateParams, $location, $timeout, $translate, filterTextFactory, contentfulFactory, userFactory, dataTransferFactory, $rootScope) {
        $scope.user = userFactory.getUserData();
        $scope.errorMsg = { visible: false, msg: "" };
        $scope.waiting = false;
        $scope.isRecipe = false;
        $scope.fromLink = false;
        $scope.passwordChanged = false;
        $scope.passwordResetting = false;
        $scope.showInvited = [true, true];

        $scope.widget = false;
        if($location.path().indexOf("widget") !== -1) {
            $scope.widget = true;
        }

        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        /*var isTouch = (ionic.Platform.isWebView() || ionic.Platform.isIOS() || ionic.Platform.isIPad()
                    || ionic.Platform.isAndroid() || ionic.Platform.isWindowsPhone());*/
        var isTouch = Modernizr.touch;

        /*************************************************
         * filterTextFactory methods for use in view
         */

        $scope.filterText = function(text) {
            if(text) {
                return filterTextFactory.filterText(text);
            }
            return null;
        };

        $scope.flowplayerFilter = function(embedCode) {
            return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
        };


        $scope.updateProtocols = function(original) {
            return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
        };

        /**
         * End filterTextFactory methods
         *************************************************/

    	function init () {
    		var currState = $state.current.name;

			// Log this page view for analytics.
			analytics.page({
				title: currState,
				url: $location.absUrl(),
				path: $location.path(),
				referrer: document.referrer
			});

			if (currState == 'about' ||
            	currState == 'terms' ||
            	currState == 'privacy') {
			  $scope.content = contentfulFactory.getCache('4ZyzGUask80Q82aWQKGq4k');
            } else if(currState == 'publicExperience') {
                $scope.resetPass = dataTransferFactory.get('resetPassword');
                if($scope.resetPass) {
                    dataTransferFactory.remove('resetPassword');
                    if (!$scope.user.authData) {
                        $scope.fromLink = true;
                        $scope.oldPassParam = $scope.resetPass.pass;
                        $scope.emailParam = $scope.resetPass.email;
                    }
                }
            }
    		else if (currState == 'legal') {
                var terms = $scope.filterText(contentfulFactory.getCmsItem('4ZyzGUask80Q82aWQKGq4k', '4Q2MyYeKX6MUq20cG6IkoS')[0].fields.content);
                var legal = $scope.filterText(contentfulFactory.getCmsItem('4ZyzGUask80Q82aWQKGq4k', '3dyJDH6iKk6YOcsEoGEAwo')[0].fields.content);
			  $scope.content = terms + legal;
    		}
            /*else if (currState == 'pointSystem') {
                $scope.content = recipesFactory.getPageContent('skills');
            }*/
            else if (currState == 'passwordReset') {
                userFactory.validateUserAuth();
            }
            else if (currState == 'changePasswordWithLink') {
                if (!$scope.user.authData && $stateParams.email && $stateParams.pass) {
                    $scope.fromLink = true;
                }
            }
            else if (currState == 'changeName') {
                userFactory.validateUserAuth();
                $scope.newName = $scope.user.userMonj.displayName;
            }
            else if (currState == 'changeEmail') {
                userFactory.validateUserAuth();
                // $scope.newEmail = $scope.user.userMonj.primaryEmail;
            }
            else if (currState == 'myProfile') {
                userFactory.validateUserAuth();

                // initialize file uploader
                $('#image-upload').fileupload({
                    dataType: 'json',
                    add: function (e, data) {
                        $("#profile-image").css("background-image", "");
                        $('.my-account-item .profile-pic').css("background-image", "");
                        $('#image-progress-container').css("visibility", "visible");
                        $("#image-progress").css("width", "0").removeClass("processing").show();
                        data.submit();
                    },
                    progressall: function (e, data) {
                        var progress = parseInt(data.loaded / data.total * 100, 10);
                        if (progress < 100) {
                            $("#image-progress").css("width", progress + "%");
                        }
                        else {
                            $("#image-progress").css("width", "100%").addClass("processing");
                        }
                    },
                    done: function (e, data) {
                        $('#image-progress-container').css("visibility", "hidden");
                        $("#image-progress").hide().removeClass("processing");
                        $.each(data.result, function (index, file) {
                            $("#profile-image")
                                .css("background-image", "url(" + file.thumbnail_url + ")")
                                .show();
                            $(".ae-header-container .image-container .image-thumb")
                                .css("background-image", "url(" + file.thumbnail_url + ")")
                                .show();
                            $('.my-account-item .profile-pic')
                                .css("background-image", "url(" + file.thumbnail_url + ")")
                                .show();
                            $scope.user.userMonj.profileImageUrl = file.thumbnail_url;
                        });
                    }
                });

                // set upload to apply to that user
                var uploadUrl = userFactory.getImageUploadURL();
                $("#image-upload").fileupload("option", "url", uploadUrl);

                // Preload username in case postSignIn fails
                $scope.userName = $scope.user.userMonj.displayName;

                if(!$scope.postSignUpCalled) {
                    $scope.postSignUpCalled = true;
                    // Update profile data to ensure myAccount data is always up to date
                    userFactory.postSignin($scope.user.userMonj.userId).then(function(result) {
                        // Bootstrap input field values for ng-models
                        $scope.userName = result.profile.displayName;
                        $scope.userEmail = result.profile.primaryEmail;
                        $scope.lang = result.profile.locale;
                        $scope.userPrivacy = !(result.profile.socialOptOut);
                        $scope.userDairy = result.profile.dairyFreePref;
                        $scope.userGluten = result.profile.glutenFreePref;
                        $scope.userSugar = result.profile.lowSugarPref;
                        $scope.userMeat = result.profile.noRedMeatPref;
                        $scope.userVeg = result.profile.vegetarianPref;
                    });
                }

                $translate('english').then(function(translation) {
                    if($scope.lang === 'en') {
                        $('.language-display-text').html(translation);
                    }
                });
                $translate('spanish').then(function(translation) {
                    if($scope.lang === 'es') {
                        $('.language-display-text').html(translation);
                    }
                });

                $scope.newName = $scope.user.userMonj.displayName;
                $scope.newEmail = $scope.user.userMonj.primaryEmail;
            }
            else if (currState == 'activityUndo') {
                userFactory.validateUserAuth();

                $scope.activity = dataTransferFactory.get('activity');
                dataTransferFactory.remove('activity');
            }

            // get all translated error messages
            $scope.ermsg = [];
            $translate('errorMsgs.invalidEmail').then(function (translation) {
                $scope.ermsg[0] = translation;
            });
            $translate('errorMsgs.invalidPass').then(function (translation) {
                $scope.ermsg[1] = translation;
            });
            $translate('errorMsgs.somethingWentWrong').then(function (translation) {
                $scope.ermsg[2] = translation;
            });
            $translate('errorMsgs.matchingPass').then(function (translation) {
                $scope.ermsg[3] = translation;
            });
            $translate('errorMsgs.missingFields').then(function (translation) {
                $scope.ermsg[4] = translation;
            });
            $translate('errorMsgs.missingName').then(function (translation) {
                $scope.ermsg[5] = translation;
            });
            $translate('errorMsgs.noEmailChange').then(function (translation) {
                $scope.ermsg[6] = translation;
            });
            $translate('errorMsgs.enterCurrentPass').then(function (translation) {
                $scope.ermsg[7] = translation;
            });
            $translate('errorMsgs.emailCannotMatch').then(function (translation) {
                $scope.ermsg[8] = translation;
            });
    	}

    	init();

        $scope.resetUser = function() {
            $translate('headerProfile.resetAccount').then(function (translation) {
                translation = "... " + translation;
                $(".reset .secondary-btn").html(translation);
            });

            // Remove old badge data screenshot
            //dataTransferFactory.remove('oldUserBadges');

            dataTransferFactory.set('demoReset', true);

            //localStorage.removeItem('defaultLanguage');
            document.cookie = "defaultLanguage=; expires=Thu, 01 Jan 1970 00:00:00 UTC";

            //$(".reset .button").html('... Reset Account');
            userFactory.resetUser($scope.user.userMonj.userId).then(function() {
                $translate('headerProfile.resetAccount').then(function (translation) {
                    translation = "&check; " + translation;
                    $(".reset .secondary-btn").html(translation);
                });
                $location.search({});
                document.cookie = "monjMealsCurrentPage=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
                //location.reload();
                document.location.hash = '#/signup-confirmed';
                $location.path($state.get('signUpConfirmed').url);
            });
        };

        $scope.modalInfo = "";

        $scope.initTeamUp = function() {
            $scope.waiting = true;
            userFactory.initChallenge().then(function(data) {
                $scope.waiting = false;
                $scope.openModal('init-teamup');
                $scope.modalInfo = data;

                //console.log(data);
            });
        };

        $scope.openTeamUp = function() {
            $scope.waiting = true;
            userFactory.openChallenge().then(function(data) {
                $scope.waiting = false;
                $scope.openModal('init-teamup');
                $scope.modalInfo = data;

                //console.log(data);
            });
        };

        $scope.goToHome = function() {
            $location.path($state.get('authExperience').url);
        };

        $scope.openModal = function(className, delay) {
            $timeout(function() {
                jQuery.event.trigger('pauseVideo');
                $('.' + className).addClass('active');
                $('.modal-background').removeClass('hide');
            }, Math.max(delay | 0, 0));
        };

        /****************************************************************
         * My Account form comparison functions
         *
         * Compares users data to what they entered to determine which
         * API calls to make. Includes validation and error messages.
         *
         * NOTE: Must include our "working" spinner on all pages
         */

        // Compares My Account data and compares it to saved user data to see if the user can access the save button
        $scope.compareForChange = function() {
            //if(!compareName() || !compareEmail() || !compareLanguage() || !comparePrivacy() || !compareDietaryPrefs()) {
                // Enable Save Button
                //$('.my-profile-container .save-button button').prop('disabled', false);
                $scope.saveMyAccountChanges();
            /*}/* else {
                $('.my-profile-container .save-button button').prop('disabled', true);
            }*/
        };

        function compareName() {
            return $scope.userName == $scope.user.userMonj.displayName;
        }

        function compareEmail() {
            return $scope.userEmail == $scope.user.userMonj.primaryEmail;
        }

        function compareLanguage() {
            return $scope.lang == $scope.user.userMonj.locale;
        }

        function comparePrivacy() {
            return $scope.userPrivacy == !($scope.user.userMonj.socialOptOut);
        }

        function compareDietaryPrefs() {
            return ( $scope.userDairy == $scope.user.userMonj.dairyFreePref ) &&
                ( $scope.userGluten == $scope.user.userMonj.glutenFreePref ) &&
                ( $scope.userSugar == $scope.user.userMonj.lowSugarPref ) &&
                ( $scope.userMeat == $scope.user.userMonj.noRedMeatPref ) &&
                ( $scope.userVeg == $scope.user.userMonj.vegetarianPref );
        }

        // Finds out which section has changed and (asynchronously) calls the APIs for each section.
        $scope.saveMyAccountChanges = function() {
            // display "working" spinner
            $scope.waiting = true;
            if(!compareName()) {
                userFactory.updateUserName($scope.user.userMonj.userId, $scope.userName).then(function() {
                    $scope.user.userMonj.displayName = $scope.userName;
                    //$scope.compareForChange();
                    $scope.waiting = false;
                });
            }

            if(!compareEmail()) {
                var isValidEmail = regex.test($scope.userEmail);

                if (!isValidEmail) {
                    $scope.errorMsg.visible = true;
                    $scope.errorMsg.msg = $scope.ermsg[0];
                    $('#email').addClass("error");
                    $scope.waiting = false;
                } else {
                    $scope.errorMsg.visible = false;
                    $('#email').removeClass("error");
                    $scope.errorMsg.msg = null;
                    userFactory.changeEmail($scope.user.authData.uid, $scope.user.userMonj.primaryEmail, $scope.userEmail, $scope.user.authData.refreshToken).then(function () {
                        $scope.waiting = false;
                    });
                }
            }

            if(!comparePrivacy()) {
                userFactory.socialOptOut($scope.user.userMonj.userId, !($scope.userPrivacy)).then(function() {
                    $scope.user.userMonj.socialOptOut = $scope.userPrivacy;
                    //$scope.compareForChange();
                    $scope.waiting = false;
                });
            }

            /* if(!compareDietaryPrefs()) {
                userFactory.dietaryPrefs($scope.user.userMonj.userId, $scope.userGluten, $scope.userVeg, $scope.userDairy, $scope.userSugar, $scope.userMeat).then(function() {
                    $scope.user.userMonj.dairyFreePref = $scope.userDairy;
                    $scope.user.userMonj.glutenFreePref = $scope.userGluten;
                    $scope.user.userMonj.lowSugarPref = $scope.userSugar;
                    $scope.user.userMonj.noRedMeatPref = $scope.userMeat;
                    $scope.user.userMonj.vegetarianPref = $scope.userVeg;

                    //$scope.compareForChange();
                    $scope.waiting = false;
                });
            }
 */
            $scope.waiting = false;
        };

        $scope.changeLang = function(lang) {
            userFactory.updateLocale($scope.user.userMonj.userId, lang).then(function(result) {
                $rootScope.lang = lang;
                $scope.lang = lang;
                $translate.use($scope.lang);

                if(isLocalStorage()){
                    localStorage.setItem("defaultLanguage", $scope.lang);
                } else {
                    setCookie('defaultLanguage', $scope.lang, 7);
                }

                // This change requires a reload
                // Give the server a second to update it's data
                $timeout(function() {
                    location.reload();
                }, 2000);
            });
        };

        /****************************************************
         * End My Account form comparison functions
         ****************************************************/

    	$scope.redirectTo = function (url, prev) {
            if (prev) {
                var prevURL = dataTransferFactory.get('prevPage');
                var parentURL = dataTransferFactory.get('parentPage');
                var currURL = $location.path();

                if (prevURL && prevURL.url && parentURL && currURL != parentURL.url)
                    $location.path($state.get(prevURL.url).url);
                else
                    $location.path($state.get(url).url);
            }
            else
                $location.path($state.get(url).url);
        }

        $scope.redirectTo2 = function (url, prev) {
            if (prev) {
                var prevURL = dataTransferFactory.get('prevPage');

                if (prevURL && prevURL.url)
                    $location.path($state.get(prevURL.url).url);
                else
                    $location.path($state.get(url).url);
            }
            else
                $location.path($state.get(url).url);
        }

        $scope.logout = function () {
            userFactory.logout();
        };

        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function isLocalStorage(){
            try {
                localStorage.setItem('localStorageTest', 'test');
                localStorage.removeItem('localStorageTest');
                return true;
            } catch(e) {
                return false;
            }
        }

        $scope.resetPassBtn = function (oldPass, pass1, pass2) {
            var email = $scope.fromLink ? $scope.emailParam : $scope.user.authData.email;
            var origPass = $scope.fromLink ? $scope.oldPassParam : oldPass;

            if (email && origPass && pass1 && pass2) {
                $('.change-password-container #old-pass').removeClass("error");
                $('.change-password-container #pass1').removeClass("error");
                $('.change-password-container #pass2').removeClass("error");

                if (pass1 == pass2) {
                    if(!$scope.passwordChanged)
                    {
                        $scope.passwordChanged = true;
                        if ($scope.waiting == false) {
                            $scope.waiting = true;
                            $scope.errorMsg.visible = false;
                            $scope.errorMsg.msg = "";
                            if(!$scope.passwordResetting) {
                                $scope.passwordResetting = true;

                                // Must reauthenticate before changing password.
                                userFactory.login($scope.user.authData.email, oldPass).then(function(data) {
                                    userFactory.changePassword(pass1).then(function (result) {
                                        $scope.passwordResetting = false;
                                        $scope.isPassReset = true;
                                        $scope.waiting = false;

                                        $timeout(function () {
                                            if ($scope.fromLink) {
                                                userFactory.login(email, pass1).then(function () {
                                                    // Raygun
                                                    Raygun.setUser(email);

                                                    if ($location.path().indexOf($state.get('passwordReset').url) != -1) {
                                                        $scope.redirectTo('myProfile', true);
                                                    } else {
                                                        $scope.redirectTo('authExperience', true);
                                                    }
                                                }, function (error) {
                                                    if ($location.path().indexOf($state.get('passwordReset').url) != -1) {
                                                        $scope.redirectTo('myProfile', true);
                                                    }
                                                });
                                            }
                                            else {
                                                $scope.redirectTo('myProfile', true);
                                            }
                                            $scope.isPassReset = true;
                                            //location.reload();
                                        }, 2000);
                                    }, function (error) {
                                        $scope.passwordResetting = false;
                                        if (error.code == "INVALID_PASSWORD") {
                                            $scope.errorMsg.msg = $scope.ermsg[1];
                                            //$scope.errorMsg.msg = "Oops! The current password is wrong. Please try again.";
                                        }
                                        else {
                                            $scope.errorMsg.msg = $scope.ermsg[2];
                                            //$scope.errorMsg.msg = "Something went wrong. Please contact the Monj support.";
                                        }

                                        $scope.errorMsg.isPlain = true;
                                        $scope.errorMsg.visible = true;
                                        $scope.waiting = false;
                                    });
                                });
                            }
                        }
                    }
                } else {
                    $('#pass1').addClass("error");
                    $('#pass2').addClass("error");

                    $scope.errorMsg.msg = $scope.ermsg[3];
                    //$scope.errorMsg.msg = "The passwords don't match.";
                    $scope.errorMsg.isPlain = true;
                    $scope.errorMsg.visible = true;
                }
            } else {
                if (!origPass)
                    $('#old-pass').addClass("error");
                else
                    $('#old-pass').removeClass("error");

                if (!pass1)
                    $('#pass1').addClass("error");
                else
                    $('#pass1').removeClass("error");

                if (!pass2)
                    $('#pass2').addClass("error");
                else
                    $('#pass2').removeClass("error");

                $scope.errorMsg.msg = $scope.ermsg[4];
                //$scope.errorMsg.msg = "Oops! Please fill in all the red fields.";
                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
            }
        }

        $scope.changeNameBtn = function (newName) {
            if (newName && newName.length > 0) {
                $('#name').removeClass("error");

                //if ($scope.waiting == false) {
                    $scope.waiting = true;
                    $scope.errorMsg.visible = false;
                    $scope.errorMsg.msg = "";

                    userFactory.updateUserName($scope.user.userMonj.userId, newName).then(function (result) {
                        $scope.user.userMonj.displayName = newName;

                        //$scope.user = userFactory.updateMonj();

                        $('#form').addClass('ng-hide');
                        $('#message').removeClass('ng-hide');
                        $scope.waiting = false;

                        /*$timeout(function () {
                            if ($location.path().indexOf($state.get('changeName').url) != -1) {
                                $scope.redirectTo('publicExperience');
                            }
                        }, 2000);*/
                    }, function (error) {
                        $scope.errorMsg.msg = $scope.ermsg[2];
                        //$scope.errorMsg.msg = "Something went wrong. Please contact the Monj support.";
                        $scope.errorMsg.isPlain = true;
                        $scope.errorMsg.visible = true;

                        $scope.waiting = false;
                    });
               // }
            }
            else {
                $('#name').addClass("error");

                $scope.errorMsg.msg = $scope.ermsg[5];
                //$scope.errorMsg.msg = "Oops! Please enter your name.";
                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
            }
        }

        $scope.changeEmailBtn = function (newEmail, password) {
            var isValidEmail = regex.test(newEmail);
            //var isUsersnap = $scope.user.usersnapLoaded;

            if (isValidEmail && password && newEmail != $scope.user.userMonj.primaryEmail) {
                $('#newEmail').removeClass("error");
                $('#pass').removeClass("error");

                if ($scope.waiting == false) {
                    $scope.waiting = true;
                    $scope.errorMsg.visible = false;
                    $scope.errorMsg.msg = "";

                    // check if email exists as primary email
                    userFactory.checkUserByPrimaryEmail(newEmail).then(function (result) {
                        if (result.found) {
                            $scope.errorMsg.msg = $scope.ermsg[6];
                            //$scope.errorMsg.msg = "Oops! We were unable to change your email address because this email already exists. Try another.";
                            $scope.waiting = false;
                            $scope.errorMsg.isPlain = true;
                            $scope.errorMsg.visible = true;
                        }
                        else {
                            userFactory.changeEmail($scope.user.authData.uid, $scope.user.userMonj.primaryEmail, newEmail, password)
                                .then(function (result) {
                                    $scope.user = userFactory.updateMonj();

                                    $('.change-email-container #form').addClass('ng-hide');
                                    $('.change-email-container #message').removeClass('ng-hide');
                                    $scope.waiting = false;

                                    $timeout(function () {
                                        /*if(isUsersnap) {
                                            $scope.redirectTo('/');
                                            location.reload();
                                        } else {
                                            $scope.redirectTo('/');
                                        }*/
                                        location.reload();
                                    }, 2000);
                                }, function (error) {
                                    switch (error) {
                                        case 'password':
                                            $scope.errorMsg.msg = $scope.ermsg[7];
                                            //$scope.errorMsg.msg = "Oops! That password is incorrect. Please enter your current password.";
                                            break;
                                        case 'create':
                                        case 'delete':
                                        default:
                                            $scope.errorMsg.msg = $scope.ermsg[2];
                                            //$scope.errorMsg.msg = "Oops! Something went wrong. Please try again later or contact Monj support.";
                                            break;
                                    }

                                    $scope.waiting = false;
                                    $scope.errorMsg.isPlain = true;
                                    $scope.errorMsg.visible = true;
                                });
                        }
                    }, function (error) {
                        $scope.errorMsg.msg = $scope.ermsg[2];
                        //$scope.errorMsg.msg = "Oops! Something went wrong. Please try again later or contact Monj support.";
                        $scope.waiting = false;
                        $scope.errorMsg.isPlain = true;
                        $scope.errorMsg.visible = true;
                    });
                }
            }
            else {
                if (!isValidEmail)
                    $('#newEmail').addClass("error");
                else
                    $('#newEmail').removeClass("error");

                if (!password)
                    $('#pass').addClass("error");
                else
                    $('#pass').removeClass("error");

                if (isValidEmail)
                    $scope.errorMsg.msg = $scope.ermsg[4];
                    //$scope.errorMsg.msg = "Oops! Please fill in all the red fields.";
                else
                    $scope.errorMsg.msg = $scope.ermsg[0];
                    //$scope.errorMsg.msg = "Oops! That email address is not valid.";

                if (newEmail == $scope.user.userMonj.primaryEmail) {
                    $('#newEmail').addClass("error");
                    $scope.errorMsg.msg = $scope.ermsg[8];
                    //$scope.errorMsg.msg = "Oops! The new email must be different than your current one.";
                }
                else
                    $('#newEmail').removeClass("error");

                $scope.errorMsg.isPlain = true;
                $scope.errorMsg.visible = true;
            }
        }

        $scope.cancelActivityUndo = function () {
            $scope.redirectTo2('/home', true);
        }

        /*$scope.openModal = function(className) {
            // $('.' + className).addClass('active');
            // $('.modal-background').toggleClass('show-dim');

            $('.' + className).toggleClass('setup-trans');
            $('.modal-background').toggleClass('setup-trans');

            setTimeout(function(){
              $('.' + className).toggleClass('trans-full');
              $('.modal-background').toggleClass('trans-half');
            }, 10);

        };

        $scope.closeModal = function() {
            $('.popup-modal').removeClass('trans-full');
            $('.modal-background').toggleClass('trans-half');

            setTimeout(function(){
              $('.popup-modal').removeClass('setup-trans');
              $('.modal-background').toggleClass('setup-trans');
            }, 350);
        };*/

        // $scope.openModal = function(className) {
        //     $('.' + className).addClass('active');
        //     $('.modal-background').removeClass('hide');
        // };
        //
        // $scope.closeModal = function() {
        //     $('.popup-modal').removeClass('active');
        //     $('.modal-background').addClass('hide');
        // };

        $scope.hideInvited = function(num) {
          $scope.showInvited[num] = false;
        }

        $scope.activityUndo = function () {
            if ($scope.waiting == false) {
                $scope.waiting = true;
                $scope.errorMsg.visible = false;
                $scope.errorMsg.msg = "";

                userFactory.undoRecipeActivity($scope.activity.activityId).then(function (result) {
                    $scope.waiting = false;
                    $scope.errorMsg.visible = false;

                    $('#message').removeClass('ng-hide');
                    $('#form').addClass('ng-hide');

                    dataTransferFactory.set('removedActivityId', $scope.activity.activityId);

                    $timeout(function () {
                        userFactory.updateMonj();
                    }, 1500);

                    $timeout(function () {
                        if ($location.path().indexOf($state.get('activityUndo').url) != -1) {
                            $scope.redirectTo2('authExperience', true);
                        }
                    }, 2000);
                }, function (error) {
                    $scope.waiting = false;
                    $scope.errorMsg.visible = true;
                    $scope.errorMsg.msg = $scope.ermsg[2];
                    //$scope.errorMsg.msg = "Oops! Something went wrong. Please try again later or contact Monj support.";
                });

            }
        }

        // $scope.restartTour = function() {
        //   // dataTransferFactory.set('demoReset', true);
        //   userFactory.updateTouredPages("reset", $scope.user.userMonj.userId).then(function() {
        //       document.location.hash = '#/signup-confirmed';
        //       $location.path($state.get('signUpConfirmed').url);
        //   });
        // }
	}

    angular.module('monjApp')
        .controller('MiscPagesController', ['$scope', '$state', '$stateParams', '$location', '$timeout', '$translate', 'filterTextFactory',
                            'contentfulFactory', 'userFactory', 'dataTransferFactory', '$rootScope', MiscPagesController]);

}());
