//hello world
//hello world
(function() {

    var EssentialsController = function ($scope, $timeout, $translate, $location, $stateParams, dataTransferFactory, userFactory, $state, animateSavedButtonFactory) {
        $scope.user = userFactory.getUserData();
        $scope.saved = false;

        $scope.currModulesData = {};
        $scope.essentials = {};
        $scope.steps = [];
        $scope.myStyle = { "width": "0px" };

        // Log this page view for analytics.
        analytics.page({
            title: 'EssentialsController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.currStepsTabHeight = 0;

        $scope.stepNumShown = 0;
        $scope.showOne = true;
        $scope.showTwo = false;

        if($state.current.name == 'essentialsWidget') {
            $scope.widget = true;

            $scope.chefHatLevels = ['newbie-color','fearless-foodie-color','fearless-foodie-p-color','fearless-foodie-pp-color',
                'confident-cook-color','confident-cook-p-color','confident-cook-pp-color','super-chef-color',
                'super-chef-p-color','super-chef-pp-color','monj-master-filled'];
        }

        function init() {

            if($stateParams.essentialsID) {
                userFactory.viewEssential($scope.user.userMonj.userId, $stateParams.essentialsID);
            }
            userFactory.getEssential($scope.user.userMonj.userId, $stateParams.essentialsID).then(function(data) {
                if(data) {
                    $scope.currEssential = data;
                    $scope.saved = $scope.currEssential.isSaved;
                    $scope.essentials.title = $scope.currModulesData.name;

                    var lastIdx = $scope.currModulesData.panels.length - 1;
                    $scope.steps = $scope.currModulesData.panels.map(function (step, idx) {
                        return {
                            title: step.title,
                            imgUrl: step.illustration,
                            text: marked(step.body),
                            monjSaysText: step.monjSays ? marked(step.monjSays) : null,
                            isLast: idx == lastIdx ? true : false
                        };
                    });

                    $scope.myStyle.width = (35 * $scope.steps.length) + "px";

                    if ($scope.currModulesData.quizQuestion) {
                        $scope.quizQuestion = {
                            question: $scope.currModulesData.quizQuestion,
                            answers: $scope.currModulesData.quizAnswers,
                        };
                    }
                }
            });

        };

        init();

        $scope.arrow = function(arrow) {
          if(arrow == 'right' && $scope.stepNumShown < $scope.steps.length - 1) {
            $scope.stepNumShown++;
            $scope.calcHeight($scope.stepNumShown);
          }
          else if (arrow == 'left' && $scope.stepNumShown > 0) {
            $scope.stepNumShown--;
            $scope.calcHeight($scope.stepNumShown);
          }
        };

        $scope.convertIndexToLetter = function(index) {
            return String.fromCharCode(65 + index);
        };

        $scope.submitPollAnswer = function(index) {
          $scope.waiting = true;
          userFactory.completeEssential($scope.user.userMonj.userId, parseInt($stateParams.essentialsID), $scope.currModulesData.quizAnswers[index]).then(function (data) {
            $scope.waiting = false;
            if(!$scope.widget){
                $location.path($state.get('authExperience').url);
            } else {
                // Trigger awards flipper, reset essentials page, and close quiz modal
                $scope.stepNumShown = 0;
                $('.flip-container').addClass('flipped');
                $('.flip-container .badge-item').addClass('show');
                $scope.user.widgetAwardData = data.outcome;
                $('.modal-background').addClass('hide');
                $('.popup-modal').removeClass('active');
                $location.path($state.href('essentialsWidget', {essentialsID: $stateParams.essentialsID}).replace('#', ''));
            }
          });
        };

        $scope.goBack = function() {
            // Trigger awards flipper and close modal
            $('.flip-container').removeClass('flipped');
            $('.flip-container .badge-item').removeClass('show');

            $location.path($state.href('essentialsWidget', {essentialsID: $stateParams.essentialsID}).replace('#', ''));
        };

        $scope.selectPollAnswer = function(index) {
            $scope.selectedPollAnswer = index;
        };

        $scope.nextSlide = function() {
          $scope.showOne = false;
          $scope.showTwo = true;
        };

        $scope.save = function() {
            if(!$scope.saved) {
                $scope.saved = true;
                animateSavedButtonFactory.animateOnSave();
                userFactory.saveEssential($scope.user.userMonj.userId, $scope.currEssential.moduleId);
                var convertedDeepDiveData = [{
                    'timestamp': new Date().toLocaleString(),
                    'contentId': $scope.currEssential.moduleId,
                    'contentType': 'Module',
                    'contentTypeEnum': 8,
                    'contentName': $scope.currEssential.name,
                    'body': null,
                    'imageUrl': $scope.currEssential.thumbUrl,
                    'gameValue': $scope.currEssential.gameValue
                }];
                dataTransferFactory.set('lastSavedDeepDive', convertedDeepDiveData);
            } else {
                $scope.saved = false;
                $('.action-container .action-icon.save.selected').css('transform', 'translate(0px, 0px)');
                userFactory.unsaveEssential($scope.user.userMonj.userId, $scope.currEssential.moduleId);
                $scope.savedMeals = dataTransferFactory.remove('lastSavedDeepDive');
            }
        };

        $scope.calcHeight = function(index) {
          if (index == $scope.stepNumShown) {
            $('#steps-parent').css("height", $('#step-' + index).outerHeight());
          }
        };


    };

    angular.module('monjApp')
        .controller('EssentialsController', ['$scope', '$timeout', '$translate', '$location', '$stateParams',
            'dataTransferFactory', 'userFactory', '$state', 'animateSavedButtonFactory', EssentialsController]);

}());
