angular.module('monjApp').value('appSettings', {
    title: 'Monj Application',
    version: '1.0',

    apiKey: "AIzaSyDf-wMksI-w31y-XNlDv-KkVkGSnkROHwk",
    authDomain: "amber-inferno-4253.firebaseapp.com",
    databaseURL: "https://amber-inferno-4253.firebaseio.com",
    projectId: "amber-inferno-4253",
    storageBucket: "amber-inferno-4253.appspot.com",
    messagingSenderId: "97827467993",

    contentfulAccessToken: '49d5a8fb60ceeee67137970485faac8d18b68094fb2c7271b7f9af66eac9d98b',
    contentfulHost: 'cdn.contentful.com',
    contentfulSpace: '5u7jt849cgc0',

    monjApiKey: 'NY28EFD3bs593BB1FhY1qu2zc2K5NFGd',
    monjURL: 'https://api.monj.com/v1',
    monjUploadURL: 'https://api.monj.com',

    segmentKey: 'A4tA0evUAFky76Q5OuQ5IJpgA0HC527f',

    isProduction: true
});
