/**
 * Created by Jared Beckerman on 4/20/2017.
 */

(function () {

    var cookieFactory = function () {
        var factory = {};

        // Set a cookie
        factory.setCookie = function(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        };

        // Gets a cookie based on its name - thank you stack-overflow ;)
        factory.getCookie = function(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        };

        // Test if LocalStorage is an option. If not, save as a cookie instead.
        factory.isLocalStorage = function(){
            try {
                localStorage.setItem('localStorageTest', 'test');
                localStorage.removeItem('localStorageTest');
                return true;
            } catch(e) {
                return false;
            }
        };

        return factory;
    };

    angular.module('monjApp').factory('cookieFactory', [cookieFactory]);

}());
