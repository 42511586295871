(function() {

    var LessonsController = function ($scope, $timeout, $location, dataTransferFactory, userFactory, userInit, factoryInit, $state, filterFactory) {
        $scope.user = userInit;
        $scope.lessons = factoryInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'LessonsController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.waiting = false;

        // Check to see if the user was routed from a challenge, and if so, grab the ID data passed in
        /*$scope.challengeData = dataTransferFactory.get('challengeData');
        // Remove challenge data once it's been retrieved. User should only see challenge data if routed from their team challenge page
        dataTransferFactory.remove('challengeData');*/

        var chalObj = $scope.user.userMonj.challenge;
        if(chalObj) {
            if(chalObj.daysLeftToEnroll <= 0 && chalObj.daysUntilChallengeEnds > 0 && chalObj.team.teamId) {
                var validIds = [];
                var ruleExists = false;
                for(var x = 0; x < chalObj.team.teamRules.length; x++) {
                    if(chalObj.team.teamRules[x].contentType == "lesson") {
                        ruleExists = true;
                        validIds = validIds.concat(chalObj.team.teamRules[x].validContentIds);
                    }
                }
                for(var x = 0; x < chalObj.team.individualRules.length; x++) {
                    if(chalObj.team.individualRules[x].contentType == "lesson") {
                        ruleExists = true;
                        validIds = validIds.concat(chalObj.team.individualRules[x].validContentIds);
                    }
                }
            }
            if(ruleExists) {
                $scope.challengeData = validIds;
            }
        }

        $scope.ddBool = false;

        $scope.locale = $scope.user.userMonj.locale;

        /***********************************
         * Filter Factory methods used by view
         */
        $scope.transparentItems = {};
        $scope.filteredTags = {};
        $scope.completedLessons = filterFactory.updateCompleted($scope.user.userMonj.completedLessonIds);

        $scope.filterClick = function(evt, clearAllSiblings) {
            filterFactory.filterClick(evt, clearAllSiblings);
            $scope.transparentItems = filterFactory.getTransparentItems();
            $scope.filteredTags = filterFactory.getFilteredTags();
        };

        $scope.filterClear = function(evt, clearTag) {
            filterFactory.filterClear(evt, clearTag);
            $scope.transparentItems = filterFactory.getTransparentItems();
        };

        $scope.itemsFilter = function(item, index, array) {
            $scope.transparentItems = filterFactory.getTransparentItems();
            return filterFactory.itemsFilter(item, index, array, $('.item-grid'));
        };

        $( window ).resize(function() {
            filterFactory.updateLabelHeights($('.item-grid'));
        });
        /**
         * End Filter Factory Methods
         ********************************************/

        function init() {

            $timeout(function() {
                // Check for heights of lesson labels after data loads
                filterFactory.updateLabelHeights($('.item-grid'));
            });

            userFactory.getLessonTopics($scope.user.userMonj.userId).then(function(data) {
                $scope.filters = data;
            });

            // Loop through the lessons object and update the tags to match the ID's used in the view
            for(var x = 0; x < $scope.lessons.length; x++) {
                $scope.lessons[x].tags = [];
                $scope.lessons[x].tags.push($scope.lessons[x].topicId.toString());

                // If challenge data exists, append the challenge tag to the appropriate items
                if($scope.challengeData) {
                    var chalLength = $scope.challengeData.length;
                    if(chalLength == 0) {
                        $scope.lessons[x].tags.push('challenge');
                    } else {
                        for (var i = 0; i < chalLength; i++) {
                            if ($scope.lessons[x].lessonId == $scope.challengeData[i]) {
                                $scope.lessons[x].tags.push('challenge');
                            }
                        }
                    }
                }
            }

            // Reset previously selected filters
            filterFactory.clearFilteredTags();

            var evt = [];
            if($scope.challengeData) {
                filterFactory.setFilteredTag('challenge', 'Challenge');
                evt.currentTarget = "#challenge";
                $scope.filterClick(evt);
            }
        };

        init();


        $scope.redirect = function(lessonId) {
            $scope.waiting = true;
            $location.path($state.href('lesson', {id: lessonId}).replace('#', ''));
        };
    };

    angular.module('monjApp')
        .controller('LessonsController', ['$scope', '$timeout', '$location', 'dataTransferFactory', 'userFactory', 'userInit', 'factoryInit', '$state', 'filterFactory', LessonsController]);

}());
