//hello world

(function() {
    var ProgressPageController = function ($scope, $timeout, $translate, $state, autoScrollFactory, $location, userInit, userFactory) {
        $scope.user = userInit;
        $scope.groupDescription = [];

        // Log this page view for analytics.
        analytics.page({
            title: 'ProgressPageController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        function init() {
            userFactory.getUserLevels($scope.user.userMonj.userId).then(function(result) {
                $scope.levels = result;
            });

            // Setup Translations for each badge row
            if($scope.user.userMonj) {
                if($scope.user.userMonj.badges.length > 0) {
                    $translate('myGoals.' + $scope.user.userMonj.badges[0].groupId).then(function (translation) {
                        $scope.groupDescription[$scope.user.userMonj.badges[0].groupId] = translation;
                    });
                }
                if($scope.user.userMonj.badges.length > 1) {
                        $translate('myGoals.' + $scope.user.userMonj.badges[1].groupId).then(function (translation) {
                        $scope.groupDescription[$scope.user.userMonj.badges[1].groupId] = translation;
                    });
                }
                if($scope.user.userMonj.badges.length > 2) {

                    $translate('myGoals.' + $scope.user.userMonj.badges[2].groupId).then(function (translation) {
                        $scope.groupDescription[$scope.user.userMonj.badges[2].groupId] = translation;
                    });
                }
                if($scope.user.userMonj.badges.length > 3) {
                    $translate('myGoals.' + $scope.user.userMonj.badges[3].groupId).then(function (translation) {
                        $scope.groupDescription[$scope.user.userMonj.badges[3].groupId] = translation;
                    });
                }
                if($scope.user.userMonj.badges.length > 4) {
                    $translate('myGoals.' + $scope.user.userMonj.badges[4].groupId).then(function (translation) {
                        $scope.groupDescription[$scope.user.userMonj.badges[4].groupId] = translation;
                    });
                }

                var scrollLocation = $location.search().scrollTo;
                if(scrollLocation) {
                    $timeout(function () {
                        autoScrollFactory.scrollTo(scrollLocation);
                    });
                }

            }

            // Loop through the badges and remove any challenge badges not at 100% completion
            for(var x = 0; x < $scope.user.userMonj.badges.length; x++) {
                if($scope.user.userMonj.badges[x].groupId == 'challenges')
                for(var y = 0; y < $scope.user.userMonj.badges[x].badges.length; y++) {
                    if($scope.user.userMonj.badges[x].badges[y].percentComplete < 1) {
                        $scope.user.userMonj.badges[x].badges.splice(y,1);
                    }
                }
            }
        };

        init();

        $(document).ready(function() {
          $timeout(function () {
            if(!($scope.user.userMonj.touredGoals)){
              $scope.startTour();
            }
          });
        });

        $scope.isMobile = function() {
          return $(window).width() <= 768;
        };

        $scope.startTour = function() {
          var tourPlacement = ["bottom"];
          if($scope.isMobile()){
            tourPlacement = ["bottom"];
          }

            // Perform Additional Translations for tour
            var gotIt = '';
            var levelUp = '';
            var levelUpDesc = '';
            var setGoals = '';
            var setGoalsDesc = '';
            $translate('tour.gotIt').then(function(translation) {
                gotIt = translation;
            });
            $translate('tour.levelUp').then(function(translation) {
                levelUp = translation;
            });
            $translate('tour.levelUpDesc').then(function(translation) {
                levelUpDesc = translation;
            });
            $translate('tour.setGoals').then(function(translation) {
                setGoals = translation;
            });
            $translate('tour.setGoalsDesc').then(function(translation) {
                setGoalsDesc = translation;
            });

          $timeout(function() {
            var tour = new Tour({
              name: 'tour',
              onShown: function(tourN){
                tourN._options.autoscroll = false;
              },
              onStart: function() {
                if($('.hamburger-menu-icon')) {
                  $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                }
              },
              onEnd: function() {
                if($('.hamburger-menu-icon')) {
                  $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                }
              },
              steps: [
              {
                element: ".points-menu-progress",
                title: levelUp,
                content: levelUpDesc,
                placement: tourPlacement[0],
                backdropContainer: "#backdrop-container",
                backdrop: true,
                onShow: function(tour) {
                  $('#prog-lev-container').addClass('temp-offset');
                  $('#prog-lev-container').addClass('temp-padding');
                },
                onHide: function(tour) {
                  tour._options.autoscroll = true;
                  $('#prog-lev-container').removeClass('temp-offset');
                  $('#prog-lev-container').removeClass('temp-padding');
                },
                template: "<div class='popover tour'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='next'>"+ gotIt +"</div></div>",
              },
              {
                element: "#section0",
                title: setGoals,
                content: setGoalsDesc,
                placement: tourPlacement[0],
                backdropContainer: "#backdrop-container",
                backdrop: true,
                onShow: function(tour) {
                  $('#section0').addClass('temp-offset');
                },
                onHide: function(tour) {
                  tour._options.autoscroll = true;
                  $('#section0').removeClass('temp-offset');
                  userFactory.updateTouredPages("goals", $scope.user.userMonj.userId);
                },
                template: "<div class='popover tour'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='end'>"+ gotIt +"</div></div>",
              }
            ]});

            //could help to clear local storage before initializing tour
            // You should selectively clean the localStorage - tour_current_step & tour_end keys by localStorage.removeItem(key); Clearing the entire localStorage is not really intended here. You can view the localStorage in developer tools (chrome) going to "Resources" tab >> "Local storage" option.
            // Clear session data
            //localStorage.clear();

            localStorage.removeItem("tour_current_step");
            localStorage.removeItem("tour_end");

            // Initialize the tour
            tour.init();

            // Start the tour
            tour.start();
          });
        };

    };

    angular.module('monjApp')
        .controller('ProgressPageController', ['$scope', '$timeout', '$translate', '$state', 'autoScrollFactory', '$location', 'userInit', 'userFactory', ProgressPageController]);

}());
